import PropTypes from 'prop-types'

const MedusaIcon = ({ className = '', ...props }) => {
  return (
    <svg
      width="54"
      height="58"
      viewBox="0 0 54 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M46.7749 9.75247L33.8679 2.32673C29.6449 -0.108911 24.4702 -0.108911 20.2471 2.32673L7.28065 9.75247C3.11709 12.1881 0.5 16.703 0.5 21.5148V36.4257C0.5 41.297 3.11709 45.7525 7.28065 48.1881L20.1877 55.6733C24.4107 58.1089 29.5854 58.1089 33.8084 55.6733L46.7155 48.1881C50.9385 45.7525 53.4961 41.297 53.4961 36.4257V21.5148C53.6151 16.703 50.998 12.1881 46.7749 9.75247ZM27.0278 42.2475C19.7118 42.2475 13.7639 36.3069 13.7639 29C13.7639 21.6931 19.7118 15.7525 27.0278 15.7525C34.3438 15.7525 40.3512 21.6931 40.3512 29C40.3512 36.3069 34.4032 42.2475 27.0278 42.2475Z"
        fill="#11181C"
      />
    </svg>
  )
}

MedusaIcon.propTypes = {
  className: PropTypes.string,
  // You can add more specific PropTypes for other SVG attributes if needed
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default MedusaIcon
