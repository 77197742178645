import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { ImageUp } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'

export default function ContenfulUploadAndLinkImagesAction({ action, isLast = false }) {
  let comment = action?.args?.comment
  const entriesImages = JSON.parse(action?.args?.entries_images)
  const uploadedImagesPaths = Object.values(entriesImages)
  const uploaded_content = uploadedImagesPaths.join('\n')
  const content = `${comment}\n\n ${uploaded_content}`
  let detailsContent = ''

  detailsContent += `## COMMENT: \n${comment}\n\n`
  const outputs = action?.outputs
  if (outputs) {
    detailsContent += `## UPLOADED IMAGES:\n${uploaded_content}`
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={ImageUp}
      isLast={isLast}
      title="Update and link images"
    >
      <AActionContentGenericLayout>
        <AActionContentDescription content={content} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

ContenfulUploadAndLinkImagesAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
