import PropTypes from 'prop-types'

export default function AActionContentGenericLayout({ children }) {
  return (
    <div className="flex max-w-full flex-col ">
      <div className="text-normal font-['Inter'] text-sm leading-normal text-stone-500">
        {children}
      </div>
    </div>
  )
}

AActionContentGenericLayout.propTypes = { children: PropTypes.node }
