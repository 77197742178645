import PropTypes from 'prop-types'

const CommerceLayerIcon = ({ className = '', ...props }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g id="Vector">
        <path
          d="M34 4H25.9C25.9 4 24.1 4.1 23 3C22.3 2.4 22 1.3 22 0H18C18 1.4 17.7 2.4 17 3C15.9 4 14.2 4 14.2 4H6C1.7 4 0 7.6 0 10V16H4V10C4 9.1 4.4 8 6 8H13.9C14.5 8 17.4 8 19.6 6C19.7 5.9 19.8 5.8 19.9 5.7C20 5.8 20.1 5.9 20.2 6C22.4 8.1 25.3 8 25.9 8H34C35.6 8 36 9.1 36 10V16H40V10C40 7.6 38.4 4 34 4Z"
          fill="black"
        />
        <path
          d="M34 36H25.9C25.9 36 24.1 35.9 23 37C22.3 37.6 22 38.7 22 40H18C18 38.6 17.7 37.6 17 37C15.9 36 14.2 36 14.2 36H6C1.7 36 0 32.4 0 30V24H4V30C4 30.9 4.4 32 6 32H13.9C14.5 32 17.4 32 19.6 34C19.7 34.1 19.8 34.2 19.9 34.3C20 34.2 20.1 34.1 20.2 34C22.4 31.9 25.3 32 25.9 32H34C35.6 32 36 30.9 36 30V24H40V30C40 32.4 38.4 36 34 36Z"
          fill="black"
        />
        <path d="M17 16H9V24H17V16Z" fill="black" />
        <path d="M31 16H23V24H31V16Z" fill="black" />
      </g>
    </svg>
  )
}

CommerceLayerIcon.propTypes = {
  className: PropTypes.string,
  // You can add more specific PropTypes for other SVG attributes if needed
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default CommerceLayerIcon
