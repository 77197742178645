import PropTypes from 'prop-types'

const ContentfulIcon = ({ className = '', ...props }) => {
  return (
    <svg
      width="36"
      height="40"
      viewBox="0 0 36 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M12.2014 27.8916C10.1218 25.7875 8.94741 22.9249 8.97188 19.9645C8.97188 17.0041 10.1463 14.1415 12.2748 12.0619C14.0854 10.4471 14.2566 7.65798 12.6418 5.84748C11.0271 4.03698 8.23789 3.86571 6.42737 5.48048C6.30504 5.60281 6.18271 5.70068 6.08484 5.82301C-1.74441 13.6033 -1.76888 26.2523 5.98698 34.0815C7.60176 35.892 10.3909 36.0388 12.2014 34.424C14.012 32.8093 14.1588 30.0201 12.544 28.2096C12.4216 28.1117 12.3238 27.9894 12.2014 27.8916Z"
        fill="#FFDA00"
      />
      <path
        d="M12.2749 12.0622C16.6544 7.70723 23.7497 7.73169 28.1047 12.1111C29.7195 13.9461 32.5086 14.1174 34.3191 12.5026C36.1297 10.8878 36.3254 8.09869 34.7106 6.28818C34.5883 6.16585 34.4659 6.01906 34.3436 5.92119C26.5878 -1.90801 13.9876 -1.9814 6.1583 5.74993C6.13384 5.77439 6.10937 5.79886 6.0849 5.82333C4.24992 7.4381 4.07866 10.2028 5.69344 12.0378C7.30822 13.8727 10.0729 14.044 11.9079 12.4292C12.0302 12.3069 12.1526 12.1845 12.2749 12.0622Z"
        fill="#67B3FF"
      />
      <path
        d="M28.0558 27.9408C25.9517 30.0449 23.0891 31.1949 20.1287 31.1949C17.1682 31.1949 14.3057 30.0205 12.226 27.8919C10.6112 26.0814 7.82206 25.9101 6.01155 27.5249C4.20104 29.1397 4.02977 31.9288 5.64455 33.7393C5.76689 33.8617 5.86475 33.984 5.98708 34.0819C13.7429 41.9111 26.3676 41.9845 34.1969 34.2287C34.2213 34.2042 34.2213 34.2042 34.2458 34.1797C35.9829 32.4671 35.9829 29.6779 34.2703 27.9408C32.5821 26.2527 29.7929 26.2282 28.0558 27.9408Z"
        fill="#EB5A68"
      />
      <path
        d="M12.0541 12.3314C10.3414 14.044 7.55228 14.044 5.81516 12.3314C4.07805 10.6188 4.10251 7.8296 5.81516 6.0925C7.52781 4.35539 10.317 4.37986 12.0541 6.0925L12.0786 6.11696C13.7667 7.85407 13.7667 10.6188 12.0541 12.3314Z"
        fill="#47A1FF"
      />
      <path
        d="M12.0541 33.935C10.3414 35.6476 7.55228 35.6476 5.81516 33.935C4.07805 32.2224 4.10251 29.4332 5.81516 27.6961C7.52781 25.9835 10.317 25.9835 12.0541 27.6961L12.0786 27.7206C13.7667 29.4577 13.7667 32.2224 12.0541 33.935Z"
        fill="#D5465F"
      />
    </svg>
  )
}

ContentfulIcon.propTypes = {
  className: PropTypes.string,
  // You can add more specific PropTypes for other SVG attributes if needed
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ContentfulIcon
