import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { CheckCheck } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'

export default function DoneAction({ action, isLast = false }) {
  return (
    <GenericAction
      action={action}
      detailsContent="## Nothing to show 🤷"
      actionIcon={CheckCheck}
      isLast={isLast}
      title="Summarize & commit"
    >
      <AActionContentGenericLayout>
        <AActionContentDescription content={action.args?.documentation || 'Done'} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

DoneAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
