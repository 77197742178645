import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { SwatchBook } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'
import AActionContentFiles from '@/components/actions/components/AActionContentFiles.jsx'
import { MExtractedColorsPreview } from '@/components/actions/components/MExtractedColorsPreview.jsx'

export default function ExtractBrandColorsAction({ action, isLast = false }) {
  const url = action?.args?.url
  const firstOutput = action?.outputs?.[0]
  const brandColors = firstOutput?.brand_colors ?? firstOutput?.content?.brand_colors
  let detailsContent = `## Colors extracted from ${url}:\n\n`
  detailsContent += '```json:\n'
  detailsContent += JSON.stringify(brandColors, null, 2)
  detailsContent += '\n```\n\n'
  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={SwatchBook}
      isLast={isLast}
      title="Extract brand colors"
    >
      <AActionContentGenericLayout>
        <AActionContentDescription content={action?.args?.comment} />
        <AActionContentFiles content={'< ' + url} />
        <MExtractedColorsPreview colors={brandColors} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

ExtractBrandColorsAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
