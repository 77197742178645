import { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'

import { Button } from '@/components/catalyst/button'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '@/components/catalyst/dialog'

import { FieldGroup } from '@/components/catalyst/fieldset'

import { useToast } from '@/components/ui/use-toast'

import { createTeam } from '@/services/Firebase'
import MTeamForm from '@/components/molecules/MTeamForm.jsx'
import { useMutation } from '@/lib/hooks.js'

export default function MCreateTeamDialog({
  isOpen,
  onClose,
  onCompleted = () => {},
  organizationId,
}) {
  const {
    response,
    mutationFn: createTeamFn,
    error: teamCreationError,
    isMutating: isCreating,
    resetFn: resetCreateTeamFn,
  } = useMutation({ mutationCallback: createTeam })
  //
  const { toast } = useToast()

  const defaultValues = useMemo(() => {
    return {
      team: { name: '', description: '' },
    }
  }, [])

  const methods = useForm({
    defaultValues: defaultValues,
  })
  const { handleSubmit, reset: resetForm } = methods

  const handleClose = useCallback(() => {
    resetForm()
    onClose()
  }, [onClose, resetForm])

  useEffect(() => {
    if (response) {
      toast({
        title: 'Team created successfully! 🎉',
        description: '',
      })
      onCompleted(response?.data || {})
      resetCreateTeamFn()
      handleClose()
    }
    if (teamCreationError) {
      toast({
        variant: 'destructive',
        title: 'Error creating a team 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error creating team:', teamCreationError)
      resetCreateTeamFn()
    }
  }, [response, teamCreationError, toast, resetCreateTeamFn, handleClose, onCompleted])

  const onSubmit = useCallback(
    data => {
      if (!isCreating) {
        createTeamFn({
          organizationId,
          ...data?.team,
        })
      }
    },
    [createTeamFn, isCreating]
  )

  return (
    <>
      <Dialog size="3xl" open={isOpen} onClose={handleClose}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Create new team</DialogTitle>
            <DialogDescription>Add a new team to the organization.</DialogDescription>
            <DialogBody>
              <FieldGroup className="space-y-8 pt-8">
                <MTeamForm />
              </FieldGroup>
            </DialogBody>
            <DialogActions>
              <Button disabled={isCreating} plain onClick={handleClose}>
                Cancel
              </Button>
              <Button
                disabled={isCreating}
                className={isCreating ? 'bg-zinc-600' : ''}
                type="submit"
              >
                {isCreating ? 'Working...' : 'Create Team'}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  )
}

MCreateTeamDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func,
  organizationId: PropTypes.string.isRequired,
}
