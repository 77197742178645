import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { updateTemplate } from '@/services/Firebase.js'
import { useToast } from '@/components/ui/use-toast.js'
import * as PropTypes from 'prop-types'
import TemplateForm from '@/components/molecules/MTemplateForm.jsx'
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components/catalyst/dialog.jsx'
import { Button } from '@/components/catalyst/button.jsx'

function useUpdateTemplate() {
  const [isUpdating, setIsUpdating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  /**
   * Update a template.
   * @param {String} templateId - The ID of the template to update.
   * @param {Object} payload - The contents of the template.
   * @returns {Promise<Object>} A promise that resolves when the template is updated.
   */
  const updateTemplateFn = async (organizationId, templateId, templateData) => {
    if (isUpdating) {
      return
    }
    setIsUpdating(true)
    setError(null)
    try {
      const payload = {
        organizationId,
        templateId,
        templateData,
      }
      const response = await updateTemplate(payload)
      setResponse(response)
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsUpdating(false)
    }
  }

  function resetUpdateTemplateFn() {
    setIsUpdating(false)
    setError(null)
    setResponse(null)
  }

  return {
    response,
    isUpdating,
    error,
    updateTemplateFn,
    resetUpdateTemplateFn,
  }
}

export default function MUpdateTemplateDialog({
  open,
  onClose,
  organizationId,
  initialValues,
  onTemplateUpdated = () => {},
}) {
  const {
    response,
    updateTemplateFn,
    error: templateUpdateError,
    isUpdating,
    resetUpdateTemplateFn,
  } = useUpdateTemplate()

  const { toast } = useToast()

  const defaultValues = useMemo(() => {
    return {
      template: {
        ...initialValues,
        usecase: {
          usecaseId: initialValues?.iterationDefaultsTemplate?.usecase?.fields[0].value,
          toolsets: initialValues?.iterationDefaultsTemplate?.usecase?.fields[1].value,
        },
        environmentVariables: initialValues?.iterationDefaultsTemplate?.environment?.fields,
      },
    }
  }, [initialValues])

  const methods = useForm({
    defaultValues: defaultValues,
  })
  const { handleSubmit, reset: resetForm } = methods

  const handleClose = useCallback(() => {
    resetForm()
    onClose()
  }, [onClose, resetForm])

  // handle post template update / error and closing the dialog
  useEffect(() => {
    if (response) {
      toast({
        title: 'Template updated successfully! 🎉',
        description: '',
      })
      onTemplateUpdated(response?.data || {})
      resetUpdateTemplateFn()
      handleClose()
    }
    if (templateUpdateError) {
      toast({
        variant: 'destructive',
        title: 'Error updating template 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error updating template:', templateUpdateError)
      resetUpdateTemplateFn()
    }
  }, [response, templateUpdateError, toast, resetUpdateTemplateFn, handleClose, onTemplateUpdated])

  const onSubmit = useCallback(
    data => {
      if (!isUpdating) {
        updateTemplateFn(organizationId, initialValues.id, {
          name: data?.template.name,
          description: data?.template.description,
          iterationDefaultsTemplate: {
            environment: { fields: data?.template.environmentVariables },
            usecase: {
              fields: [
                { key: 'usecaseId', value: data?.template?.usecase?.usecaseId },
                { key: 'toolsets', value: data?.template?.usecase?.toolsets },
              ],
            },
          },
        })
      }
    },
    [updateTemplateFn, isUpdating, initialValues, organizationId]
  )

  return (
    <Dialog size="3xl" open={open} onClose={handleClose}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Update Template</DialogTitle>
          <DialogBody>
            <TemplateForm />
          </DialogBody>
          <DialogActions>
            <Button disabled={isUpdating} plain onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={isUpdating} className={isUpdating ? 'bg-zinc-600' : ''} type="submit">
              {isUpdating ? 'Working...' : 'Update Template'}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}

MUpdateTemplateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  onTemplateUpdated: PropTypes.func,
  organizationId: PropTypes.string.isRequired,
}
