import { useMemo, useState, Fragment } from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { SlashIcon } from '@radix-ui/react-icons'
import PropTypes from 'prop-types'
import { ITERATION_STATUSES } from '@/const/const.js'
import {
  CheckCheck,
  GitCompare,
  Loader,
  MoreVertical,
  PauseCircle,
  PlaneTakeoff,
  SquarePlus,
  TimerReset,
  TvMinimal,
  XOctagon,
} from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { SiGithub } from '@icons-pack/react-simple-icons'
import useStore from '@/stores/useStore.js'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics.js'
import { Link } from '@/components/catalyst/link.jsx'
import {
  convertGitHubSSHToHTTPS,
  getPreviewUrl,
  hasValidBranch,
  hasValidCommitHashes,
} from '@/lib/git-utils.js'
import Logo from '@/assets/svg-components/Logo.jsx'
import colors from 'tailwindcss/colors'
import { formatIterationDate } from '@/lib/date-utils.js'
import { Button } from '@/components/ui/button'
import MCreateIterationDialog from '@/components/molecules/iteration-details/CreateIteration/MCreateIterationDialog.jsx'
import { stackTemplate } from '@/const/stackTemplates.ts'

export default function IterationHeader({
  organization,
  project,
  team,
  iterations,
  activeIterationId,
  onActiveSelected = iterationId => {
    console.log('Selected iteration', iterationId)
  },
  isLoading = false,
  onIterationCreated = () => {
    console.log('Default onIterationCreated function')
  },
}) {
  const breadcrumbs = useMemo(() => {
    return [
      {
        name: organization?.name || 'Organization',
        href: `/organizations/${project?.organizationId}`,
      },
      {
        name: team?.name || 'Team',
        href: `/teams/${project?.teamId}`,
      },
      {
        name: project?.name || 'Project',
        current: true,
      },
    ]
  }, [organization, team, project])

  const [isCreateIterationDialogOpen, setIsCreateIterationDialogOpen] = useState(false)

  const selectedIteration = useMemo(() => {
    return iterations?.find(iteration => iteration?.id === activeIterationId)
  }, [activeIterationId, iterations])

  const activeIterationStatus = useStore(state => state.activeIterationStatus)

  const [iterationStatus, statusColor, statusIcon] = useMemo(() => {
    switch (activeIterationStatus) {
      case ITERATION_STATUSES.AWAITING:
        return [
          'Awaiting',
          'zinc-500',
          <PlaneTakeoff key="iteration-status-awaiting-icon" className="text-zinc-500" />,
        ]
      case ITERATION_STATUSES.RUNNING:
        return [
          'Running',
          'violet-500',
          <Logo key="iteration-status-running-icon" color={colors.violet[500]} />,
        ]
      case ITERATION_STATUSES.PAUSED:
        return [
          'Paused',
          'zinc-500',
          <PauseCircle key="iteration-status-paused-icon" className="text-zinc-500" />,
        ]
      case ITERATION_STATUSES.DONE:
        return [
          'Done',
          'green-500',
          <CheckCheck key="iteration-status-done-icon" className="text-green-500" />,
        ]
      case ITERATION_STATUSES.FAILED:
        return [
          'Failed',
          'red-500',
          <XOctagon key="iteration-status-failed-icon" className="h-6 w-5 text-red-500" />,
        ]
      case ITERATION_STATUSES.RUN_REQUESTED:
        return [
          'Requested',
          'zinc-500',
          <TimerReset key="iteration-status-requested-icon" className="text-zinc-500" />,
        ]
      default:
        return [
          activeIterationStatus,
          'zinc-500',
          <Logo key="iteration-status-running-icon" color={colors.zinc[500]} />,
        ]
    }
  }, [activeIterationStatus])

  const stack =
    selectedIteration?.configuration?.techStack ?? selectedIteration?.configuration?.technologies
  const TechnologyIcon = stackTemplate[stack]?.icon

  const previewUrl = getPreviewUrl(selectedIteration?.preview_urls)

  const projectName = `${project?.name ?? selectedIteration?.configuration?.prospectName ?? 'Current project'}`

  return (
    <>
      <div className="inline-flex h-[314px] w-full flex-col items-start justify-start gap-8 rounded-lg border border-stone-200 bg-white pb-8 shadow ">
        <div
          className={`inline-flex items-center justify-between self-stretch rounded-lg border-b  border-stone-200 bg-gradient-to-r from-transparent via-transparent to-${statusColor}/20 px-8 py-4`}
        >
          <div className="flex items-center justify-start gap-0.5">
            <Breadcrumb>
              <BreadcrumbList>
                {breadcrumbs.map((breadcrumb, index) => {
                  if (breadcrumb.current) {
                    return (
                      <BreadcrumbItem key={index}>
                        <BreadcrumbLink>{breadcrumb?.name ?? '...'}</BreadcrumbLink>
                      </BreadcrumbItem>
                    )
                  } else {
                    return (
                      <Fragment key={index}>
                        <BreadcrumbItem>
                          <BreadcrumbLink>{breadcrumb?.name ?? '...'}</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator>
                          <SlashIcon />
                        </BreadcrumbSeparator>
                      </Fragment>
                    )
                  }
                })}
              </BreadcrumbList>
            </Breadcrumb>
          </div>
          <div className="flex items-center justify-start gap-3 bg-transparent">
            <div
              className={`font-['PP Supply Sans'] text-xs font-normal uppercase leading-none text-${statusColor} bg-transparent`}
            >
              {iterationStatus}
            </div>
            <div className="inline-flex h-5 w-5 flex-col items-center justify-center">
              {statusIcon}
            </div>
          </div>
        </div>

        <div className="inline-flex items-center justify-between self-stretch px-8">
          <div className="flex items-center justify-start gap-3">
            <div className="font-['PP Supply Sans'] text-3xl font-normal leading-10 text-stone-900">
              {projectName}
            </div>
          </div>
          <div className="flex items-center justify-start gap-2">
            <div className="flex h-10 w-10 items-center justify-center gap-2 rounded-md bg-white/0 px-4 py-2">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-8 w-8 p-0">
                    <MoreVertical className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {/*<DropdownMenuItem*/}
                  {/*  onClick={() => {*/}
                  {/*    setIsCreateIterationDialogOpen(true)*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <SquarePlus className="mr-2 h-4 w-4" />*/}
                  {/*  <span>Create iteration</span>*/}
                  {/*</DropdownMenuItem>*/}
                  {hasValidCommitHashes(selectedIteration) && (
                    <DropdownMenuItem>
                      <Link
                        href={`${convertGitHubSSHToHTTPS(selectedIteration?.repoURI)}/compare/${selectedIteration?.startCommitHash}...${selectedIteration?.endCommitHash}`}
                        target={'_blank'}
                        className="flex items-center"
                        onClick={() => {
                          analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_REPO_CHANGES, {
                            iterationId: selectedIteration?.id,
                            projectId: selectedIteration?.projectId,
                            teamId: selectedIteration?.teamId,
                            organizationId: selectedIteration?.organizationId,
                            status: selectedIteration?.status,
                          })
                        }}
                      >
                        <GitCompare className="mr-2 h-4 w-4" />
                        <span>Code changes</span>
                      </Link>
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
        <div className="flex h-[122px] flex-col items-start justify-start gap-4 self-stretch px-8">
          <div className="inline-flex items-center justify-start gap-2 self-stretch">
            <div className="w-66 inline-flex flex-col items-start justify-start gap-1.5 rounded-md border border-stone-200 bg-white px-4 py-3">
              <div className="inline-flex items-center justify-between self-stretch">
                <div className="font-['Inter'] text-xs font-normal leading-none text-stone-500">
                  Last activity
                </div>
                <div className="relative h-4 w-4" />
              </div>
              <div className="self-stretch font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                {formatIterationDate(selectedIteration?.updatedAt, 'unknown')}
              </div>
            </div>
            <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1.5 rounded-md border border-stone-200 bg-white px-4 py-3">
              <div className="inline-flex items-center justify-between self-stretch">
                <div className="font-['Inter'] text-xs font-normal leading-none text-stone-500">
                  Technology
                </div>
                <div className="relative h-4 w-4" />
              </div>
              <div className="inline-flex items-start justify-start gap-3">
                <div className="px flex h-4 w-4 items-center justify-center">
                  {TechnologyIcon && <TechnologyIcon />}
                </div>
                <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                  {stackTemplate[stack]?.label ?? ''}
                </div>
              </div>
            </div>
          </div>
          <div className="inline-flex items-start justify-start gap-4 self-stretch">
            <div
              className={`flex h-10 shrink grow basis-0 items-center justify-center gap-2 rounded-md ${selectedIteration?.repoURI && hasValidBranch(selectedIteration) ? 'bg-stone-900' : 'bg-stone-400'} px-4 py-2`}
            >
              <div className="w-full font-['Inter'] text-sm font-medium leading-tight text-stone-50">
                {selectedIteration?.repoURI && hasValidBranch(selectedIteration) ? (
                  <Link
                    href={`${convertGitHubSSHToHTTPS(selectedIteration?.repoURI)}/tree/${selectedIteration?.branchName}`}
                    target={'_blank'}
                    className="flex w-full items-center justify-center gap-2"
                    onClick={() => {
                      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_REPO, {
                        iterationId: selectedIteration?.id,
                        projectId: selectedIteration?.projectId,
                        teamId: selectedIteration?.teamId,
                        organizationId: selectedIteration?.organizationId,
                        status: selectedIteration?.status,
                      })
                    }}
                  >
                    <SiGithub className="relative h-4 w-4 text-white" />
                    <span>Repository</span>
                  </Link>
                ) : (
                  <div className="opacity-3 flex w-full items-center justify-center gap-2">
                    <Loader className="relative h-4 w-4 text-white" />
                    <span>Repository</span>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`flex h-10 shrink grow basis-0 items-center justify-center gap-2 rounded-md ${previewUrl ? 'bg-stone-900' : 'bg-stone-400'} px-4 py-2`}
            >
              <div className="w-full font-['Inter'] text-sm font-medium leading-tight text-stone-50">
                {previewUrl ? (
                  <Link
                    href={previewUrl}
                    target={'_blank'}
                    className="flex w-full items-center justify-center gap-2"
                    onClick={() => {
                      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_PREVIEW, {
                        iterationId: selectedIteration?.id,
                        projectId: selectedIteration?.projectId,
                        teamId: selectedIteration?.teamId,
                        organizationId: selectedIteration?.organizationId,
                        status: selectedIteration?.status,
                        vmStatus: selectedIteration?.vmStatus,
                      })
                    }}
                  >
                    <TvMinimal className="relative h-4 w-4 text-white" />
                    <span>Preview</span>
                  </Link>
                ) : (
                  <div className="flex w-full items-center justify-center gap-2">
                    <Loader className="relative h-4 w-4 text-white" />
                    <span>Preview</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedIteration?.projectId && isCreateIterationDialogOpen && (
        <MCreateIterationDialog
          key={selectedIteration?.id + '-create-iteration-dialog-beta'}
          projectId={selectedIteration?.projectId}
          isOpen={isCreateIterationDialogOpen}
          onClose={() => {
            setIsCreateIterationDialogOpen(false)
          }}
          sourceIterationData={selectedIteration || {}}
          isFirstIterationForProject={false}
          onIterationCreated={onIterationCreated}
        />
      )}
    </>
  )
}

IterationHeader.propTypes = {
  iterations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      prompt: PropTypes.string,
      status: PropTypes.string,
      updatedAt: PropTypes.shape({
        toDate: PropTypes.func,
      }),
    })
  ),
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  activeIterationId: PropTypes.string,
  onActiveSelected: PropTypes.func,
  lastTick: PropTypes.number,
  onIterationCreated: PropTypes.func,
}
