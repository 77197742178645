import PropTypes from 'prop-types'

const VtexIcon = ({ className = '', ...props }) => {
  return (
    <svg
      width="48"
      height="40"
      viewBox="0 0 48 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g id="VTEX_idzLb-5Fgk_4 1" clipPath="url(#clip0_779_916)">
        <path
          id="Vector"
          d="M43.8931 0H8.95615C6.24714 0 4.50963 2.87716 5.78007 5.27791L9.27376 11.8916H2.94028C1.65116 11.8916 0.614258 12.9379 0.614258 14.2177C0.614258 14.5913 0.707674 14.965 0.885162 15.3013L12.1229 36.581C12.7208 37.7207 14.1313 38.1504 15.271 37.5525C15.682 37.3377 16.0183 36.9921 16.2425 36.581L19.2971 30.8361L23.1271 38.085C24.4723 40.6352 28.1248 40.6352 29.4793 38.0943L46.9945 5.13778C48.2182 2.81177 46.5274 0 43.8931 0ZM28.1995 14.0869L20.6516 28.2952C20.2499 29.0518 19.3158 29.3414 18.5591 28.9397C18.2882 28.7903 18.064 28.5661 17.9146 28.2952L10.4321 14.1429C10.0304 13.3863 10.32 12.4521 11.0767 12.0504C11.3009 11.929 11.5437 11.873 11.7959 11.873H26.8637C27.6951 11.873 28.3676 12.5455 28.3676 13.3863C28.377 13.6198 28.3116 13.8627 28.1995 14.0869Z"
          fill="#F71963"
        />
        <path
          id="Vector_2"
          d="M43.8931 0H8.95615C6.24714 0 4.50963 2.87716 5.78007 5.27791L9.27376 11.8916H2.94028C1.65116 11.8916 0.614258 12.9379 0.614258 14.2177C0.614258 14.5913 0.707674 14.965 0.885162 15.3013L12.1229 36.581C12.7208 37.7207 14.1313 38.1504 15.271 37.5525C15.682 37.3377 16.0183 36.9921 16.2425 36.581L19.2971 30.8361L23.1271 38.085C24.4723 40.6352 28.1248 40.6352 29.4793 38.0943L46.9945 5.13778C48.2182 2.81177 46.5274 0 43.8931 0ZM28.1995 14.0869L20.6516 28.2952C20.2499 29.0518 19.3158 29.3414 18.5591 28.9397C18.2882 28.7903 18.064 28.5661 17.9146 28.2952L10.4321 14.1429C10.0304 13.3863 10.32 12.4521 11.0767 12.0504C11.3009 11.929 11.5437 11.873 11.7959 11.873H26.8637C27.6951 11.873 28.3676 12.5455 28.3676 13.3863C28.377 13.6198 28.3116 13.8627 28.1995 14.0869Z"
          fill="#F71963"
        />
      </g>
      <defs>
        <clipPath id="clip0_779_916">
          <rect width="46.7912" height="40" fill="white" transform="translate(0.604492)" />
        </clipPath>
      </defs>
    </svg>
  )
}

VtexIcon.propTypes = {
  className: PropTypes.string,
  // You can add more specific PropTypes for other SVG attributes if needed
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default VtexIcon
