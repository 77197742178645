import { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { InformationCircleIcon } from '@heroicons/react/24/solid/index.js'
import { Button } from '@/components/catalyst/button.jsx'

import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics'
import { useSearchParams } from 'react-router-dom'
import { useClipboardCopy } from '@/lib/hooks.js'
import { useToast } from '@/components/ui/use-toast.js'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import PropTypes from 'prop-types'
import { ACTION_STATUSES, ELEMENT_STATUSES } from '@/const/const.js'
import { GitCompare, GitCompareIcon, MoreVertical, SquarePlus, XOctagon } from 'lucide-react'
import Logo from '@/assets/svg-components/Logo.jsx'
import colors from 'tailwindcss/colors.js'
import { safeParseInt } from '@/lib/params-utils.js'
import { Button as ShadCNButton } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { convertGitHubSSHToHTTPS, hasValidCommitHashes } from '@/lib/git-utils.js'
import { Link } from '@/components/catalyst/link.jsx'
import MRestartFromAction from '@/components/molecules/iteration-details/MRestartFromAction.jsx'

export default function GenericAction({
  duration,
  action,
  title,
  detailsContent,
  actionIcon,
  customOptions = {},
  isLast = false,
  children,
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showRestore, setShowRestore] = useState(false)
  const selectedActionIndex = safeParseInt(searchParams.get('action'))
  const { toast } = useToast()
  const { copy: copyUrl } = useClipboardCopy()

  const [isJSONViewOpen, setIsJSONViewOpen] = useState(false)
  const [isLLMPlaygrounOpen, setIsLLMPlaygrounOpen] = useState(false)

  const [isActionExpanded, setIsActionExpanded] = useState(selectedActionIndex === action?.index)

  const actionDataToDisplayOnHover = useMemo(() => {
    return _.omit(action, [
      // 'actionId',
      // 'elementId',
      // 'stepId',
      // 'iterationId',
      'projectId',
      'index',
      'status',
      'updatedAt',
      'createdAt',
      'startedAt',
      'endedAt',
      'updatedAtRelative',
      'shouldBeDeleted',
    ])
  }, [action])

  const shouldAllowLLMPlayground = useMemo(() => {
    return action?.type === 'llm' && action?.subtype === 'request'
  }, [action])

  const ActionIcon = useMemo(() => {
    return actionIcon ?? InformationCircleIcon
  }, [actionIcon])

  const handleShowDetails = useCallback(() => {
    if (!isJSONViewOpen) {
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_ACTION_DETAILS, {
        iterationId: action?.iterationId,
        elementIndex: action?.elementIndex,
        stepIndex: action?.stepIndex,
        actionIndex: action?.index,
        actionType: action.type,
        actionName: action.name,
        status: action.status,
      })
    }
    setIsJSONViewOpen(true)
  }, [isJSONViewOpen, action])

  const handleDeepLinkAction = () => {
    setSearchParams(
      prevParams => {
        const newParams = new URLSearchParams(prevParams)
        if (action?.index) {
          newParams.set('action', action.index)
        } else {
          newParams.delete('action')
        }
        newParams.set('element', action.elementIndex)
        newParams.set('step', action.stepIndex)
        return newParams
      },
      { replace: false }
    )

    // copyUrl(
    //   window.location.href,
    //   () =>
    //     toast({
    //       title: 'Action link copied to clipboard! 🎉',
    //     }),
    //   () =>
    //     toast({
    //       variant: 'destructive',
    //       title: 'Failed to copy action link to clipboard 😔',
    //       description: 'Check console for details and try again.',
    //     })
    // )
  }

  const [actionStatusIcon, actionTextColor, actionStatusIconBg] = useMemo(() => {
    switch (action.status) {
      case ACTION_STATUSES.AWAITING:
        return [<ActionIcon className="text-stone-400" />, 'stone-500', 'stone-50']
      case ACTION_STATUSES.RUNNING:
        return [
          <Logo key="step-running-logo" color={colors.violet[500]} className="h-6 w-6" />,
          'stone-900',
          'violet-50',
        ]
      case ACTION_STATUSES.DONE:
        return [<ActionIcon className="text-green-500" />, 'stone-500', 'green-50']
      case ACTION_STATUSES.FAILED:
        return [<XOctagon className="h-6 w-5 text-red-500" />, 'red-600', 'red-50']
      default:
        return [<ActionIcon className="text-stone-400" />, 'stone-500', 'stone-50']
    }
  }, [action.status])

  const handleToggleExpandAction = isExpanded => {
    if (isExpanded) {
      handleDeepLinkAction()
    }
    setIsActionExpanded(isExpanded)
  }

  return (
    <div className="flex w-full flex-col">
      <MRestartFromAction
        action={action}
        showUserInput={showRestore}
        setShowUserInput={setShowRestore}
      />
      <Collapsible
        open={isActionExpanded}
        onOpenChange={handleToggleExpandAction}
        className="w-full space-y-2"
        data-index={action.index}
      >
        <div className="flex w-full items-center justify-start gap-4 self-stretch">
          <div className="flex h-10 shrink grow basis-0 items-center justify-start gap-4">
            <div
              className={`flex h-10 w-10 items-center justify-center rounded-md bg-${actionStatusIconBg}`}
            >
              <div className="relative h-5 w-5">{actionStatusIcon}</div>
            </div>
            <div
              className={`shrink grow basis-0 font-['Inter'] text-sm font-normal leading-tight text-${actionTextColor}`}
            >
              {title ?? action.name}
            </div>
            <div className="text-right font-['Inter'] text-xs font-normal leading-none text-stone-500">
              {getActionDuration(action)}
            </div>
          </div>
          <div className="flex items-center justify-start gap-1">
            <div className="flex h-8 w-8 items-center justify-center gap-2 rounded-md bg-white/0 px-4 py-2">
              <div className="relative h-3 w-3" />
            </div>
            <div className="flex h-8 w-8 items-center justify-center gap-2 rounded-md bg-white/0 px-4 py-2">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <ShadCNButton variant="ghost" className="h-8 w-8 p-0">
                    <MoreVertical className="h-4 w-4" />
                  </ShadCNButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {/*<DropdownMenuItem*/}
                  {/*  onClick={() => {*/}
                  {/*    setShowRestore(true)*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <SquarePlus className="mr-2 h-4 w-4" />*/}
                  {/*  <span>Restore before action</span>*/}
                  {/*</DropdownMenuItem>*/}
                  {hasValidCommitHashes(action) && (
                    <DropdownMenuItem>
                      <Link
                        href={`${convertGitHubSSHToHTTPS(action?.repoURI)}/compare/${action?.startCommitHash}...${action?.endCommitHash}`}
                        target="_blank"
                        className="flex items-center"
                        onClick={() => {
                          analyticsTrackEvent(
                            ANALYTIC_EVENTS.ITERATION_CLICK_REPO_CHANGES_STEP_ACTION,
                            {
                              iterationId: action?.iterationId,
                              elementIndex: action?.elementIndex,
                              stepIndex: action?.stepIndex,
                              actionIndex: action?.index,
                              actionType: action.type,
                              actionName: action.name,
                              status: action.status,
                            }
                          )
                        }}
                      >
                        <GitCompare className="mr-2 h-4 w-4" />
                        <span>Code changes</span>
                      </Link>
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              <CollapsibleTrigger asChild>
                <Button plain>{isActionExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}</Button>
              </CollapsibleTrigger>
            </div>
          </div>
        </div>
        <CollapsibleContent className="w-full space-y-2">{children}</CollapsibleContent>
      </Collapsible>
    </div>
  )
}

GenericAction.propTypes = {
  action: actionShape,
  title: PropTypes.string,
  detailsContent: PropTypes.string,
  actionIcon: PropTypes.elementType,
  customOptions: PropTypes.shape({
    iconStyle: PropTypes.string,
    actionBodyStyle: PropTypes.string,
  }),
  isLast: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

function getActionDuration(action) {
  if (!action?.elapsedTime) {
    return ''
  }
  const elapsedTime = _.round(action.elapsedTime, 2)
  return `${elapsedTime} sec`
}
