import { Card, CardContent } from '@/components/ui/card'
import { Check } from 'lucide-react'

export function EmailLinkSentStep({ email }: { email: string | null; onNext: () => void }) {
  return (
    <Card className="rounded-lg border-base-border bg-base-card p-8 shadow-none">
      <CardContent>
        <div className="space-y-8">
          <div className="space-y-4">
            <div className="font-pp-supply-sans text-3xl font-normal">Sign in</div>
            <div>
              <Check className="text-2xl text-green-500" />
            </div>
            <div className="space-y-1">
              <div className="font-medium text-base-foreground">Magic link sent to {email}</div>
              <div className="text-sm text-base-muted-foreground">
                Check your inbox and spam folder and just click on the link we sent you!
              </div>
            </div>
          </div>
          <div className="text-sm text-base-muted-foreground">
            Interested in Proofs? <a className="underline">Early access?</a>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
