const safeParseInt = value => {
  const parsed = parseInt(value, 10)
  return isNaN(parsed) ? null : parsed
}

function formatIndex(current) {
  if (current == null) {
    return '0'.toString().padStart(2, '0')
  }
  if (current < 100) {
    return current.toString().padStart(2, '0')
  } else {
    return current
  }
}

export { safeParseInt, formatIndex }
