import { useOnboardingContext } from '../OnboardingProvider'
import { TechStackCard } from './TechStackCard'
import { Button } from '@/components/ui/button'
import { ArrowRightIcon } from 'lucide-react'
import { USECASE_ID } from '@/const/stackTemplates.ts'
import ShopifyIcon from '@/assets/svg-components/stacks/ShopifyIcon'
import ShopwareIcon from '@/assets/svg-components/stacks/ShopwareIcon'
import CommerceLayerIcon from '@/assets/svg-components/stacks/CommerceLayerIcon'
import SapCommerceIcon from '@/assets/svg-components/stacks/SapCommerceIcon'
import VtexIcon from '@/assets/svg-components/stacks/VtexIcon'
import SalesforceCommerceCloudIcon from '@/assets/svg-components/stacks/SalesforceCommerceCloudIcon'
import ContentfulIcon from '@/assets/svg-components/stacks/ContentfulIcon'
import SprykerIcon from '@/assets/svg-components/stacks/SprykerIcon'
import OrdercloudIcon from '@/assets/svg-components/stacks/OrdercloudIcon'
import MedusaIcon from '@/assets/svg-components/stacks/MedusaIcon'

export const TECH_STACKS = [
  {
    title: 'Shopify Hydrogen',
    icon: <ShopifyIcon className="size-10" />,
    category: 'commerce',
    id: USECASE_ID.SHOPIFY_HYDROGEN,
  },
  {
    title: 'Shopware',
    icon: <ShopwareIcon className="size-10" />,
    category: 'commerce',
    id: USECASE_ID.SHOPWARE,
  },
  {
    title: 'CommerceLayer',
    icon: <CommerceLayerIcon className="size-10" />,
    category: 'commerce',
    id: USECASE_ID.COMMERCE_LAYER,
  },
  {
    title: 'SAP Commerce',
    icon: <SapCommerceIcon className="size-10" />,
    category: 'commerce',
    id: USECASE_ID.SAP_COMMERCE,
  },
  {
    title: 'Vtex',
    icon: <VtexIcon className="size-10" />,
    category: 'commerce',
    isComingSoon: true,
    id: USECASE_ID.VTEX,
  },
  {
    title: 'Salesforce Commerce Cloud',
    icon: <SalesforceCommerceCloudIcon className="size-10" />,
    category: 'commerce',
    isComingSoon: true,
    id: USECASE_ID.SALESFORCE_COMMERCE_CLOUD,
  },
  {
    title: 'Medusa',
    icon: <MedusaIcon className="size-10" />,
    category: 'commerce',
    isComingSoon: true,
    id: USECASE_ID.MEDUSA,
  },
  {
    title: 'Contentful',
    icon: <ContentfulIcon className="size-10" />,
    category: 'cms',
    isComingSoon: true,
    id: USECASE_ID.CONTENTFUL,
  },
  {
    title: 'Sprykyer',
    icon: <SprykerIcon className="size-10" />,
    category: 'commerce',
    isComingSoon: true,
    id: USECASE_ID.SPRYKER,
  },
  {
    title: 'OrderCloud',
    icon: <OrdercloudIcon className="size-10" />,
    category: 'commerce',
    isComingSoon: true,
    id: USECASE_ID.ORDERCLOUD,
  },
]

export function ChooseTechStackStep() {
  const { selectedTechStacks, setSelectedTechStacks } = useOnboardingContext()
  const { setCurrentStepIndex } = useOnboardingContext()

  return (
    <div className="space-y-2">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {TECH_STACKS.map(techStack => (
          <TechStackCard
            key={techStack.id}
            {...techStack}
            isSelected={selectedTechStacks?.includes(techStack.id)}
            onClick={() => {
              // if (techStack.isComingSoon) return

              if (selectedTechStacks?.includes(techStack.id)) {
                setSelectedTechStacks(selectedTechStacks.filter(tech => tech !== techStack.id))
              } else {
                setSelectedTechStacks([...(selectedTechStacks || []), techStack.id])
              }
            }}
          />
        ))}
      </div>
      <Button
        type="button"
        className="sticky bottom-0 left-0 right-0 h-11 w-full bg-base-foreground"
        disabled={!selectedTechStacks?.length}
        onClick={() => setCurrentStepIndex(currentStep => currentStep + 1)}
      >
        Next <ArrowRightIcon className="ml-2 size-4" />
      </Button>
    </div>
  )
}
