import { Card, CardContent } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useMemo } from 'react'
import { sendSignInLinkToEmail } from 'firebase/auth'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics'
import { ROUTES } from '@/const/routes'
import { LOCAL_STORAGE_EMAIL_LOGIN_KEY } from '@/const/const'
import { auth, doesUserExistFirebaseFunction } from '@/services/Firebase'

const formSchema = z.object({
  email: z.string().email(),
})

type FormSchema = z.infer<typeof formSchema>

export function EnterEmailStep({
  setEmail,
  onNext,
}: {
  email: string | null
  setEmail: (email: string) => void
  onNext: () => void
}) {
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  })

  const currentDomain = useMemo(() => {
    return window?.location?.origin
  }, [])

  const sendSignInLinkViaEmail = async data => {
    try {
      const result = (await doesUserExistFirebaseFunction({ email: data.email })) as {
        data: { userExists: boolean }
      }
      const { userExists } = result.data

      if (userExists) {
        const actionCodeSettings = {
          url: `${currentDomain}${ROUTES.SIGN_IN_WITH_LINK_CONFIRMATION}`,
          handleCodeInApp: true,
        }

        await sendSignInLinkToEmail(auth, data.email, actionCodeSettings)

        window.localStorage.setItem(LOCAL_STORAGE_EMAIL_LOGIN_KEY, data.email)
        analyticsTrackEvent(ANALYTIC_EVENTS.SIGN_IN_EMAIL_SENT, { email: data.email })
        setEmail(data.email)
        onNext()
      } else {
        analyticsTrackEvent(ANALYTIC_EVENTS.AUTH_SIGN_IN_EMAIL_NOT_FOUND, { email: data.email })
      }
    } catch (err) {
      analyticsTrackEvent(ANALYTIC_EVENTS.AUTH_SIGN_IN_ERROR, { error: err.message })
    }
  }

  return (
    <Card className="bg-base-card border-base-border rounded-lg p-8 shadow-none">
      <CardContent>
        <Form {...form}>
          <form
            className="space-y-8"
            onSubmit={form.handleSubmit(sendSignInLinkViaEmail)}
            method="POST"
          >
            <div className="space-y-4">
              <div className="font-pp-supply-sans text-3xl font-normal">Sign in</div>
              <div className="text-base-muted-foreground text-sm">
                Get a magic link sent to your email. <br />
                Make sure you have access to your inbox.
              </div>
            </div>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="w-full" type="submit" disabled={form.formState.isSubmitting}>
              Send Magic Link
            </Button>
            <div className="text-base-muted-foreground text-sm">
              Interested in Proofs? <a className="underline">Early access?</a>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  )
}
