'use client'

import { useState } from 'react'
import { BriefStep } from '@/components/molecules/iteration-details/CreateIteration/BriefStep'
import { IterationFormData } from '@/components/molecules/iteration-details/CreateIteration/types'
import { PromptStep } from '@/components/molecules/iteration-details/CreateIteration/PromptStep'
import { FormStep } from '@/components/molecules/iteration-details/CreateIteration/FormStep'
import { stackTemplate } from '@/const/stackTemplates'

export default function MCreateIterationWizard({
  onCreateIteration,
  initialPrompt,
  submittingInProgress,
}: {
  onCreateIteration: (any) => void
  initialPrompt?: string
  submittingInProgress: boolean
}) {
  const [step, setStep] = useState<'form' | 'prompt' | 'brief'>('form')
  const [formValues, setFormValues] = useState<IterationFormData>({
    prospectName: '',
    prospectWebsite: '',
    projectTemplate: null,
    technologies: '',
    whatToBuild: '',
    technicalRequirements: '',
    prospectRequirements: '',
  })
  const [formIsValid, setFormIsValid] = useState(false)
  const [prompt, setPrompt] = useState(
    initialPrompt ??
      "Let's build a POC for a new prospect, {prospectName}.\n" +
        'Their website is {prospectWebsite}'
  )

  const handleFormSubmit = (data: IterationFormData, isValid: boolean) => {
    setFormValues(data)
    setFormIsValid(isValid)
    setStep('brief')
  }

  const handlePromptChange = (newPrompt: string) => {
    setPrompt(newPrompt)
  }
  let newPrompt = `Let's build a POC for a new prospect, ${formValues.prospectName}.
Their website is ${formValues.prospectWebsite}`
  if (stackTemplate[formValues.technologies]?.promptExtras != null) {
    newPrompt += `\n\n${stackTemplate[formValues.technologies].promptExtras}`
  }

  const handleSubmit = (data: IterationFormData, isValid: boolean) => {
    setFormValues(data)
    setFormIsValid(isValid)
    onCreateIteration({
      configuration: { ...data },
      prompt: newPrompt,
      // usecase: {
      //   usecaseId: stackTemplate[formValues.technologies].usecaseId,
      //   toolsets: stackTemplate[formValues.technologies].toolsets,
      // },
    })
    // Handle final submission here
  }

  const handleSeePrompt = () => {
    setStep('prompt')
  }

  return (
    <>
      {step === 'form' && (
        <FormStep
          onSubmit={handleSubmit}
          initialValues={formValues}
          onSeePrompt={handleSeePrompt}
          submittingInProgress={submittingInProgress}
        />
      )}
      {step === 'prompt' && (
        <PromptStep
          prompt={prompt}
          onPromptChange={handlePromptChange}
          onBack={() => setStep('form')}
          onNext={() => setStep('brief')}
          formIsValid={formIsValid}
        />
      )}
      {step === 'brief' && (
        <BriefStep
          submittingInProgress={submittingInProgress}
          formValues={formValues}
          prompt={prompt}
          onBack={() => setStep('form')}
          onSubmit={handleSubmit}
        />
      )}
    </>
  )
}
