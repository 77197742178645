import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/catalyst/table'
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '@/components/catalyst/dropdown.jsx'
import { ChevronDownIcon } from '@heroicons/react/16/solid/index.js'
import MUpdateTemplateDialog from '@/components/molecules/MUpdateTemplateDialog.jsx'
import MConfirmationDialog from '@/components/molecules/MConfirmationDialog.jsx'
import { useToast } from '@/components/ui/use-toast.js'
import { deleteTemplate } from '@/services/Firebase.js'

const MINUTE_IN_MS = 60000

export default function OTemplatesTable({ organizationId, templates }) {
  const [lastTick, setLastTick] = useState(Date.now())
  const [isUpdateTemplateDialogOpen, setIsUpdateTemplateDialogOpen] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [isConfirmDeleteTemplateDialogOpen, setIsConfirmDeleteTemplateDialogOpen] = useState(false)

  const { toast } = useToast()

  useEffect(() => {
    const interval = setInterval(() => {
      setLastTick(Date.now())
    }, MINUTE_IN_MS)
    return () => clearInterval(interval)
  }, [])

  const templatesWithRelativeTime = useMemo(() => {
    // lastTick is used to force a re-render every minute
    if (lastTick && templates) {
      return Object.entries(templates)?.map(([id, template]) => ({
        id,
        ...template,
        updatedAtRelative: DateTime.fromJSDate(
          template?.updatedAt?.toDate() || Date.now()
        ).toRelative(),
      }))
    }
  }, [templates, lastTick])

  const handleUpdateTemplate = template => {
    setSelectedTemplate(template)
    setIsUpdateTemplateDialogOpen(true)
  }

  const handleDeleteTemplate = template => {
    setSelectedTemplate(template)
    setIsConfirmDeleteTemplateDialogOpen(true)
  }

  const handleConfirmDeleteTemplate = async () => {
    try {
      await deleteTemplate({ organizationId, templateId: selectedTemplate.id })
      toast({
        title: 'Template deleted successfully! 🎉',
        description: '',
      })
    } catch (error) {
      console.log('error deleting template', error)
      toast({
        variant: 'destructive',
        title: 'Error deleting template 😔',
        description: 'Check console for details and try again.',
      })
    }
    setIsConfirmDeleteTemplateDialogOpen(false)
  }

  return (
    <>
      {' '}
      <Table grid className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Description</TableHeader>
            <TableHeader>Updated</TableHeader>
            <TableHeader>Actions</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {templatesWithRelativeTime?.map(template => (
            <TableRow key={template.id} title={template.name}>
              <TableCell
                className="max-w-64"
                onClick={() => {
                  handleUpdateTemplate(template)
                }}
              >
                <div className="flex cursor-pointer items-center">
                  <div>
                    <div className="max-w-64 truncate font-medium">{template.name}</div>
                  </div>
                </div>
              </TableCell>

              <TableCell className="">
                <div className=" flex items-center gap-4 overflow-hidden">
                  <div className="max-w-96 truncate">
                    <div className="truncate font-medium">{template.description}</div>
                  </div>
                </div>
              </TableCell>

              <TableCell className="w-32 text-mimirGray-500">
                <span>{template.updatedAtRelative}</span>
              </TableCell>

              <TableCell className="w-32">
                <Dropdown>
                  <DropdownButton outline>
                    Actions
                    <ChevronDownIcon />
                  </DropdownButton>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleUpdateTemplate(template)}>
                      <DropdownLabel>Edit template</DropdownLabel>
                    </DropdownItem>
                    <DropdownItem onClick={() => handleDeleteTemplate(template)}>
                      <DropdownLabel>Delete template</DropdownLabel>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isUpdateTemplateDialogOpen && (
        <MUpdateTemplateDialog
          organizationId={organizationId}
          initialValues={selectedTemplate}
          onClose={() => {
            setIsUpdateTemplateDialogOpen(false)
          }}
          open={isUpdateTemplateDialogOpen}
        />
      )}
      {isConfirmDeleteTemplateDialogOpen && (
        <MConfirmationDialog
          onConfirm={handleConfirmDeleteTemplate}
          onCancel={() => {
            setIsConfirmDeleteTemplateDialogOpen(false)
          }}
          open={isConfirmDeleteTemplateDialogOpen}
          title="Delete template"
        />
      )}
    </>
  )
}
OTemplatesTable.propTypes = {
  templates: PropTypes.object,
  organizationId: PropTypes.string,
}
