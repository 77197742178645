import PropTypes from 'prop-types'

const SprykerIcon = ({ className = '', ...props }) => {
  return (
    <svg
      width="26"
      height="40"
      viewBox="0 0 26 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g id="Spryker Systems_idDzoJyYFN_4 1" clipPath="url(#clip0_779_943)">
        <g id="Group">
          <path
            id="Vector"
            d="M14.1638 37.3242C14.0934 37.3946 13.9526 37.4651 13.8821 37.5355C13.7413 37.6059 13.6709 37.6763 13.53 37.7468C13.4596 37.8172 13.3188 37.958 13.3188 38.0284C13.2483 38.0989 13.2483 38.2397 13.2483 38.3806V38.451V38.5214V38.5918V38.6622C13.1779 38.8031 13.1075 38.8735 13.0371 39.0144C12.9666 39.1552 12.8962 39.2256 12.8258 39.296C12.7554 39.3665 12.685 39.4369 12.6145 39.5073C12.5441 39.5777 12.4033 39.5777 12.2624 39.5777C12.1216 39.5777 11.9807 39.5777 11.8399 39.5073C11.699 39.4369 11.5582 39.4369 11.4878 39.3665C11.3469 39.2961 11.2765 39.2256 11.1357 39.1552C10.9948 39.0848 10.9244 38.9439 10.7835 38.8735C10.7835 38.8735 10.7835 38.8735 10.7835 38.8031C10.7835 38.8031 10.7835 38.8031 10.7835 38.7327C10.7835 38.7327 10.7835 38.7327 10.7835 38.6622C10.7835 38.6622 10.7835 38.6622 10.7835 38.5918C10.7131 38.451 10.7131 38.3806 10.6427 38.3101C10.5723 38.2397 10.5019 38.1693 10.4314 38.0989C10.361 38.0284 10.2906 37.958 10.2202 37.8876C10.1497 37.8172 10.0089 37.7468 9.93848 37.7468C9.93848 37.7468 9.93848 37.7468 9.93848 37.8172C9.93848 37.8172 9.93848 37.8172 9.93848 37.8876V37.958V38.0284C10.0089 38.3101 10.1497 38.5918 10.361 38.8031C10.5019 39.0144 10.7131 39.2256 10.9244 39.4369C11.1357 39.6482 11.3469 39.789 11.6286 39.8594C11.8399 40.0003 12.1216 40.0707 12.4033 40.0707C12.685 40.0707 12.9666 40.0707 13.1779 39.9299C13.3892 39.8594 13.6004 39.6482 13.8117 39.4369C14.023 39.2256 14.0934 38.9439 14.1638 38.6622C14.2343 38.3806 14.2343 38.0284 14.1638 37.6763V37.3242Z"
            fill="black"
          />
          <path
            id="Vector_2"
            d="M3.45821 23.9436C3.45821 23.9436 3.38779 23.9436 3.38779 24.014C3.38779 24.014 3.31736 24.014 3.31736 24.0844C3.31736 24.1548 3.24694 24.0844 3.24694 24.1548L3.17652 24.2253C3.03567 24.2957 2.89483 24.4365 2.75398 24.507C2.61314 24.5774 2.47229 24.6478 2.33145 24.7887C2.1906 24.9295 2.12018 25.0703 1.97933 25.2112C1.90891 25.352 1.83849 25.5633 1.76807 25.7746C2.04976 25.7041 2.26102 25.7041 2.54271 25.7746C2.8244 25.845 3.10609 25.9154 3.31736 25.9858C3.59905 26.1267 3.88074 26.2675 4.16243 26.4084C4.44412 26.6196 4.72581 26.8309 5.0075 27.0422L3.88074 23.8027H3.81032H3.7399C3.7399 23.8027 3.66948 23.8027 3.66948 23.8732C3.52863 23.9436 3.52863 23.9436 3.45821 23.9436Z"
            fill="black"
          />
          <path
            id="Vector_3"
            d="M4.37402 23.8027L5.43036 26.9717L7.82473 27.0422C7.61346 26.5492 7.33177 26.0563 7.05008 25.7041C6.76839 25.352 6.4867 24.9999 6.20501 24.7182C5.92332 24.4365 5.57121 24.2253 5.28952 24.0844C4.9374 23.8732 4.65571 23.8027 4.37402 23.8027Z"
            fill="black"
          />
          <path
            id="Vector_4"
            d="M5.7832 27.8872C6.13532 28.3097 6.48743 28.8027 6.83954 29.3661C7.19165 29.9295 7.54377 30.5633 7.89588 31.1971C8.24799 31.9013 8.52968 32.6055 8.81137 33.3802C9.09306 34.1548 9.37475 34.9999 9.58602 35.9154C9.58602 35.5633 9.58602 35.1407 9.58602 34.7182C9.58602 34.2957 9.58602 33.9435 9.5156 33.521C9.5156 33.0985 9.44518 32.6759 9.37475 32.2534C9.30433 31.8309 9.23391 31.4083 9.16349 30.9858C9.09306 30.7041 9.02264 30.4224 8.95222 30.1407C8.88179 29.859 8.81137 29.5773 8.74095 29.3661C8.67053 29.0844 8.60011 28.8731 8.52968 28.5914C8.45926 28.3802 8.31841 28.0985 8.24799 27.8872H5.7832Z"
            fill="black"
          />
          <path
            id="Vector_5"
            d="M0.36084 4.71826C0.431262 5.35206 0.501685 6.05629 0.572107 6.69009C0.64253 7.3239 0.783375 8.02812 0.853798 8.73235C0.92422 9.43657 1.06507 10.0704 1.20591 10.7746C1.34676 11.4788 1.41718 12.183 1.55802 12.8873C1.76929 13.8028 1.91014 14.7183 2.1214 15.5633C2.33267 16.4788 2.54394 17.3239 2.75521 18.169C2.96647 19.014 3.17774 19.8591 3.45943 20.6338C3.6707 21.4084 3.95239 22.183 4.16366 22.9577C4.3045 22.9577 4.37493 22.9577 4.51577 22.9577C4.65662 22.9577 4.72704 22.9577 4.86788 22.9577C5.00873 22.9577 5.07915 23.0281 5.22 23.0281C5.36084 23.0985 5.43126 23.0985 5.57211 23.169C5.36084 22.6056 5.07915 21.9718 4.86788 21.338C4.65662 20.7042 4.37493 20.0704 4.16366 19.4366C3.95239 18.8028 3.74112 18.0985 3.52985 17.4647C3.31859 16.8309 3.10732 16.0563 2.89605 15.3521C2.61436 14.4366 2.40309 13.5211 2.1214 12.6056C1.76929 11.6197 1.55802 10.7042 1.34676 9.85911C1.13549 9.01404 0.994643 8.09854 0.783375 7.25347C0.64253 6.33798 0.501685 5.49291 0.36084 4.71826Z"
            fill="black"
          />
          <path
            id="Vector_6"
            d="M21.6273 18.1693C21.4865 18.1693 21.3456 18.2397 21.2048 18.2397C21.0639 18.2397 20.9231 18.3101 20.7823 18.3101C20.6414 18.3805 20.5006 18.3805 20.3597 18.451C20.2189 18.5214 20.078 18.5214 19.9372 18.5918L19.5146 22.6059C19.9372 22.1129 20.4301 21.62 20.9231 21.1974C21.4161 20.7749 21.909 20.3524 22.402 20.0003C22.8949 19.6481 23.4583 19.296 23.9513 19.0143C24.5146 18.7327 25.0076 18.451 25.571 18.2397C25.2189 18.1693 24.9372 18.0989 24.6555 18.0284C24.3738 17.958 24.0217 17.958 23.74 17.958C23.4583 17.958 23.1062 17.958 22.7541 18.0284C22.402 18.0989 22.1203 18.0989 21.6273 18.1693Z"
            fill="black"
          />
          <path
            id="Vector_7"
            d="M18.2469 24.2256L12.4723 27.1129C12.4019 27.465 12.3314 27.7467 12.261 28.0988C12.1906 28.4509 12.1906 28.7326 12.1202 29.0847C12.1202 29.4369 12.0497 29.789 12.1202 30.1411C12.1202 30.4932 12.1202 30.8453 12.1906 31.1974C12.261 31.6904 12.3314 32.2538 12.4723 32.6763C12.6131 33.1692 12.754 33.6622 12.8948 34.0847C13.1061 34.5777 13.3173 35.0002 13.5286 35.4228C13.7399 35.8453 14.0216 36.1974 14.3033 36.62C14.3033 35.4228 14.4441 34.2256 14.6554 33.0988C14.8666 31.9721 15.1483 30.8453 15.5004 29.8594C15.8526 28.8031 16.2751 27.8171 16.7681 26.9016C17.1202 25.9157 17.6835 25.0707 18.2469 24.2256Z"
            fill="black"
          />
          <path
            id="Vector_8"
            opacity="0.7"
            d="M16.4873 0C16.6986 0.704225 16.8394 1.47887 16.9803 2.25352C17.1211 3.02817 17.1916 3.87324 17.262 4.71831C17.3324 5.56338 17.4028 6.47887 17.4028 7.39436C17.4732 8.30986 17.4732 9.29577 17.4732 10.2817C17.5437 12.6056 17.3324 14.4366 17.0507 16.0563C16.769 17.6761 16.2761 18.9437 15.7831 20C15.2901 21.0563 14.7268 21.9718 14.2338 22.8169C13.7408 23.5915 13.3183 24.3662 13.0366 25.3521V25.4225V25.493V25.5634V25.6338L18.5296 22.9577C18.8113 21.1972 19.0225 19.5775 19.093 18.0282C19.1634 16.5493 19.2338 15.1408 19.1634 13.8028C19.1634 12.5352 19.0225 11.338 18.9521 10.2817C18.8113 9.22535 18.7408 8.23944 18.5296 7.32394C18.3887 6.69014 18.3183 5.98591 18.1775 5.35211C18.0366 4.71831 17.8958 4.08451 17.6845 3.4507C17.4732 2.8169 17.262 2.25352 17.1211 1.69014C16.9099 1.12676 16.6986 0.56338 16.4873 0Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_779_943">
          <rect width="25.2817" height="40" fill="white" transform="translate(0.359375)" />
        </clipPath>
      </defs>
    </svg>
  )
}

SprykerIcon.propTypes = {
  className: PropTypes.string,
  // You can add more specific PropTypes for other SVG attributes if needed
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default SprykerIcon
