import { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { useToast } from '@/components/ui/use-toast'
import PropTypes from 'prop-types'
import { Input } from '@/components/catalyst/input.jsx'
import { ITERATION_COMMANDS, ITERATION_STATUSES } from '@/const/const.js'

import { createIterationCommandFirebaseFunction } from '@/services/Firebase.js'
import { Button } from '@/components/ui/button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { cn } from '@/lib/utils'
import { MoveLeft, Plus, SquareArrowRight } from 'lucide-react'
import useStore, { useIsUserSuperAdmin } from '@/stores/useStore'
import { Textarea } from '@/components/ui/textarea'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics'
import colors from 'tailwindcss/colors'
import Logo from '@/assets/svg-components/Logo.jsx'

const SHOULD_START_GUNSLINGER = window?.location?.host?.startsWith('old.') ? false : true

type Extension = {
  id: string
  title: string
  prompt: string
  placeholder: string
  blueprintId?: string
}

type ExtensionOption = { type: 'extension'; extension: Extension } | { type: 'custom' }

export default function MContinuationPrompt({
  iterationId,
  className,
  iterationUsecaseId,
  command,
}: {
  iterationId: string
  className: string | undefined
  iterationUsecaseId: string
  command: ITERATION_COMMANDS
}) {
  const { getConfigurationTemplateByUsecaseId } = useStore()
  const configurationTemplate = getConfigurationTemplateByUsecaseId(iterationUsecaseId)
  const extensions = configurationTemplate?.iterationDefaultsTemplate?.usecase?.extensions
  const [selectedOption, setSelectedOption] = useState<ExtensionOption | null>(() => {
    if (!extensions || extensions?.length === 0) {
      return { type: 'custom' }
    }
    return null
  })
  const [isWorking, setIsWorking] = useState(false)
  const [shouldHide, setShouldHide] = useState(false)
  const isUserSuperAdmin = useIsUserSuperAdmin()
  const [isOpen, setIsOpen] = useState(false)

  const { toast } = useToast()

  const handleCustomClick = useCallback(() => {
    setSelectedOption({ type: 'custom' })
  }, [])

  const handleExtensionOptionClick = useCallback(extension => {
    setSelectedOption({ type: 'extension', extension })
  }, [])

  const handleBack = useCallback(() => {
    setSelectedOption(null)
    if (!extensions || extensions.length === 0) {
      setIsOpen(false)
    }
  }, [extensions])

  const handleSubmit = useCallback(
    async ({ prompt, blueprintId }) => {
      if (!prompt || prompt?.length === 0 || !iterationId) {
        toast({
          variant: 'destructive',
          title: 'We need instructions!',
          description: 'Please provide instructions on how to continue',
        })
        return
      }
      setIsWorking(true)
      try {
        const payload = {
          iterationId: iterationId,
          command: ITERATION_COMMANDS.EXTEND,
          commandArgs: {
            prompt,
            blueprintId: (blueprintId === '') | (blueprintId == null) ? null : blueprintId,
          },
          dontStartGunslinger: !SHOULD_START_GUNSLINGER,
        }

        await createIterationCommandFirebaseFunction(payload)
        analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXTEND, {
          iterationId: iterationId,
          prompt: prompt,
        })
        setShouldHide(true)
      } catch (error) {
        console.error('Error creating continuation prompt', error)
        toast({
          variant: 'destructive',
          title: 'Error extending iteration 😔',
          description: 'Try refreshing the page or contact Proofs team.',
        })
      } finally {
        setIsWorking(false)
      }
    },
    [iterationId, toast]
  )

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open)
      if (open && (!extensions || extensions.length === 0)) {
        setSelectedOption({ type: 'custom' })
      }
    },
    [extensions]
  )

  if (shouldHide) {
    return null
  }

  if (isWorking || command === ITERATION_COMMANDS.EXTEND) {
    return (
      <div className="inline-flex h-16 items-center justify-start gap-6">
        <div className="flex h-16 w-16 items-center justify-center rounded-md bg-[#f8f3fe] p-5">
          <div className="relative flex h-6 w-6 flex-col items-start justify-start">
            <Logo key="iteration-status-running-icon" color={colors.violet[500]} />
          </div>
        </div>
        <div className="font-['Inter'] text-base font-medium leading-normal text-stone-900">
          Working on a solution
        </div>
      </div>
    )
  }

  return (
    <div className={cn('cursor-pointer rounded-lg border border-stone-200', className)}>
      <Collapsible open={isOpen} onOpenChange={handleOpenChange}>
        <CollapsibleTrigger asChild>
          <div className="inline-flex h-[72px] w-full items-center justify-between rounded-lg border border-stone-200 bg-white py-1 pl-1 pr-6 shadow">
            <div className="flex items-center justify-start gap-6">
              <div className="flex h-16 w-16 items-center justify-center rounded-md bg-[#f9f1f8] p-[22px]">
                <div className="relative flex h-5 w-5 flex-col items-start justify-start">
                  <SquareArrowRight className="text-violet-500" />
                </div>
              </div>
              <div className="font-['Inter'] text-base font-medium leading-normal text-stone-900">
                Continue project
              </div>
            </div>
            <div className="font-['Inter'] text-sm font-normal leading-tight text-stone-500">
              {`What's next?`}
            </div>
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent>
          {selectedOption ? (
            <PromptEditor
              option={selectedOption}
              isSuperAdmin={isUserSuperAdmin}
              onBack={handleBack}
              onSubmit={handleSubmit}
            />
          ) : (
            <div className="flex w-full flex-wrap items-center justify-start gap-1 p-2">
              {extensions?.map(extension => (
                <FollopUpOption
                  key={extension.id}
                  Icon={Plus}
                  label={extension.title}
                  onClick={() => handleExtensionOptionClick(extension)}
                  extensionId={extension.id}
                />
              ))}
              <FollopUpOption Icon={Plus} label="Custom" onClick={handleCustomClick} />
            </div>
          )}
        </CollapsibleContent>
      </Collapsible>
    </div>
  )
}

MContinuationPrompt.propTypes = {
  iterationId: PropTypes.string,
}

function FollopUpOption({
  label,
  onClick,
  Icon,
}: {
  label?: string
  onClick?: () => void
  Icon: React.FC
}) {
  return (
    <Button variant="secondary" className="bg-stone-200" onClick={onClick}>
      <div className="inline-flex h-10 w-full items-center justify-center gap-2 rounded-md  px-4 py-2">
        {Icon && <Icon className="h-4 w-4" />}
        <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
          {label}
        </div>
      </div>
    </Button>
  )
}

const CUSTOM_OPTION_PLACEHOLDER = 'Instructions on what to do next, please be specific'

function isExtensionWithBlueprintId(option: ExtensionOption): boolean {
  return option.type === 'extension' && option.extension.blueprintId
}

function isExtensionWithoutBlueprintId(option: ExtensionOption): boolean {
  return option.type === 'extension' && !option.extension.blueprintId
}

function isCustomOption(option: ExtensionOption): boolean {
  return option.type === 'custom'
}
function getBlueprintIdFromOption(option: ExtensionOption): string | null {
  return option.type === 'extension' && option.extension.blueprintId
    ? option.extension.blueprintId
    : null
}

function PromptEditor({
  option,
  onBack,
  onSubmit,
  isSuperAdmin = false,
}: {
  option: ExtensionOption
  onBack: () => void
  onSubmit: () => void
  isSuperAdmin: boolean
}) {
  const [prompt, setPrompt] = useState<string>(() => {
    if (isExtensionWithoutBlueprintId(option)) {
      return option.extension.prompt
    } else {
      return ''
    }
  })
  const [blueprintId, setBlueprintId] = useState<string | null>(() => {
    return getBlueprintIdFromOption(option) ?? ''
  })
  const existingPrompt = option.type === 'extension' ? option.extension.prompt : ''
  const isSubmitDisabled = option.type === 'custom' ? prompt === '' : false
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
      const length = textareaRef.current.value.length
      textareaRef.current.setSelectionRange(length, length)
    }
  }, [])

  const handleOnSubmit = useCallback(() => {
    if (isExtensionWithBlueprintId(option)) {
      onSubmit({ prompt: `${existingPrompt}\n\n${prompt}`, blueprintId })
    } else if (isExtensionWithoutBlueprintId(option) || isCustomOption(option)) {
      onSubmit({ prompt, blueprintId })
    }
  }, [option, prompt, blueprintId])

  return (
    <div className="inline-flex  w-full flex-col items-start justify-start bg-white">
      <div className="flex  flex-col items-start justify-start gap-2 self-stretch border-b border-stone-200 bg-stone-100 px-6 py-4">
        <div className="inline-flex items-center justify-start gap-2 py-0.5">
          <div className="flex h-4 w-4 items-center justify-center p-0.5">
            <Plus />
          </div>
          <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
            {option.type === 'extension' ? option.extension.title : 'Custom'}
          </div>
        </div>
        {isExtensionWithBlueprintId(option) && (
          <div className="self-stretch whitespace-pre-wrap font-['Inter'] text-sm font-normal leading-tight text-stone-500">
            {existingPrompt}
          </div>
        )}
      </div>
      <div className="inline-flex  items-center justify-center gap-2 self-stretch px-6 py-5">
        <div className="flex w-full flex-col">
          {isSuperAdmin && (
            <div className="mb-4 w-full">
              <Input
                placeholder="Blueprint ID (optional)"
                value={blueprintId}
                onChange={e => setBlueprintId(e.target.value)}
              />
            </div>
          )}
          <div className="  shrink grow basis-0">
            <Textarea
              ref={textareaRef}
              className="resize-none border-0 font-['Inter'] text-sm font-normal leading-tight text-stone-500 shadow-none ring-0 focus:outline-none focus:ring-0 focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
              value={prompt}
              rows={3}
              onChange={e => setPrompt(e.target.value)}
              placeholder={
                option.type === 'extension'
                  ? option.extension.placeholder
                  : CUSTOM_OPTION_PLACEHOLDER
              }
            />
          </div>
        </div>
      </div>
      <div className="inline-flex items-start justify-start gap-2 self-stretch px-6 pb-5">
        <div
          className="flex h-10 w-10 cursor-pointer items-center justify-center gap-2 rounded-md border border-stone-200 px-4 py-2 "
          onClick={onBack}
        >
          <div className="relative flex h-4 w-4 items-center">
            <MoveLeft />
          </div>
        </div>
        <Button
          disabled={isSubmitDisabled}
          className="flex h-10 shrink grow basis-0 items-center justify-center gap-2 rounded-md bg-stone-900 px-4 py-2"
          onClick={handleOnSubmit}
        >
          <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-50">
            Request
          </div>
          <SquareArrowRight className="relative h-4 w-4 text-stone-50" />
        </Button>
      </div>
    </div>
  )
}
