import PropTypes from 'prop-types'

const OrdercloudIcon = ({ className = '', ...props }) => {
  return (
    <svg
      width="38"
      height="40"
      viewBox="0 0 38 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g id="Group 2">
        <path
          id="Vector"
          d="M16.086 17.7199L26.0315 11.9702V0.587357C26.0315 0.140589 25.5458 -0.150781 25.1573 0.0823151L12.0458 7.65793C11.8709 7.75506 11.7544 7.9493 11.7544 8.16298V15.2141L1.5953 9.34788C1.20681 9.11478 0.721191 9.40615 0.721191 9.85292V29.0833C0.721191 29.297 0.83774 29.4913 1.01256 29.5884L16.086 38.2906V17.7199Z"
          fill="#EB001A"
        />
        <path
          id="Vector_2"
          d="M19 39.9806L36.9872 29.5884C37.1621 29.4913 37.2786 29.297 37.2786 29.0834V9.85298C37.2786 9.40621 36.793 9.11484 36.4045 9.34794L19 19.4099V40V39.9806Z"
          fill="#F47F8C"
        />
      </g>
    </svg>
  )
}

OrdercloudIcon.propTypes = {
  className: PropTypes.string,
  // You can add more specific PropTypes for other SVG attributes if needed
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default OrdercloudIcon
