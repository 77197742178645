import React from 'react'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { ErrorMessage, Field, Label } from '@/components/catalyst/fieldset.jsx'
import { Input } from '@/components/catalyst/input.jsx'
import { Textarea } from '@/components/catalyst/textarea.jsx'

const MOrganizationForm = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext() // Retrieve the context of the parent form

  return (
    <>
      <div>
        <Field>
          <Label>Name</Label>
          <Input
            {...register('organization.name', {
              required: 'Please fill in a name for the organization',
            })}
            invalid={!!errors?.organization?.name}
          />
        </Field>
        {errors?.organization?.name && (
          <ErrorMessage className="">{errors?.organization?.name.message}</ErrorMessage>
        )}
      </div>

      <div>
        <Field>
          <Label>Description</Label>
          <Textarea
            {...register('organization.description', {})}
            invalid={!!errors?.organization?.description}
            rows={10}
          />
        </Field>
        {errors?.organization?.description && (
          <ErrorMessage className="pt-0">{errors?.organization?.description.message}</ErrorMessage>
        )}
      </div>
    </>
  )
}

MOrganizationForm.propTypes = {}

export default MOrganizationForm
