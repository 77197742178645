import logoText from '@/assets/images/logo-text.svg'
import logo from '@/assets/images/logo.svg'
import sphereDone from '@/assets/images/sphere-done.svg'
import sphereLoading from '@/assets/images/sphere-loading.svg'
import { Button } from '@/components/ui/button'
import { useEffect, useState } from 'react'
import { useOnboardingContext } from '@/components/pages/v2/POnboarding/OnboardingProvider'
import { onboardUserFirebaseFunction } from '@/services/Firebase'

const MINIMUM_LOADING_TIME_SECONDS = 5

export function POnboardingBuilding() {
  const [isReady, setIsReady] = useState(false)
  const { selectedTechStacks } = useOnboardingContext()
  const [updatingUser, setUpdatingUser] = useState(false)

  useEffect(() => {
    let timeoutId

    // Create a promise that resolves only after setTimeout completes
    const timerPromise = new Promise(resolve => {
      timeoutId = setTimeout(() => {
        resolve() // Now the Promise resolves when setTimeout callback runs
      }, MINIMUM_LOADING_TIME_SECONDS * 1000)
    })

    // Wait for both promises to resolve
    Promise.all([timerPromise])
      .then(() => {
        setIsReady(true)
      })
      .catch(error => {
        console.error('Error during onboarding:', error)
      })

    // Cleanup timeout if component unmounts
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [selectedTechStacks])

  const handleRedirectToProject = async () => {
    setUpdatingUser(true)
    await onboardUserFirebaseFunction({ selectedTechStacks })
  }

  return (
    <div className="h-screen w-screen p-12">
      <div className="mx-auto flex h-full max-w-[684px] flex-col gap-12">
        <div className="flex items-center justify-between">
          <img src={logoText} alt="proofs logo text" className="size-16" />
          <img src={logo} alt="proofs logo" className="size-6" />
        </div>
        <div className="flex flex-1 flex-col items-center justify-center gap-8">
          <img src={isReady ? sphereDone : sphereLoading} className="size-36" />
          <div className="flex flex-col items-center gap-2">
            <div className="text-3xl text-foreground">
              {isReady ? `It's done and done!` : 'Next, our AI Agents get to work'}
            </div>
            <div className="text-sm text-base-muted-foreground">
              {isReady
                ? `When you're build is ready, you can check out the code, see the preview or continue the journey.`
                : `The build usually takes a couple of minutes, but we will fast-forward`}
            </div>
          </div>
          {isReady && (
            <Button disabled={updatingUser} onClick={handleRedirectToProject}>
              {updatingUser ? 'Redirecting...' : 'See your PoC 👀'}
            </Button>
          )}
        </div>
        <div className="h-16" />
      </div>
    </div>
  )
}
