import { useMemo, useState, useCallback } from 'react'

import PropTypes from 'prop-types'

import { StopIcon, PlayIcon } from '@heroicons/react/20/solid'

import { Rocket, BadgeCheck, PlaneTakeoff, Construction } from 'lucide-react'

import { Badge } from '@/components/catalyst/badge'
import { Button } from '@/components/catalyst/button'

import { ITERATION_STATUSES, ITERATION_STATUS_TO_COMMAND, ITERATION_COMMANDS } from '@/const/const'

import { createIterationCommandFirebaseFunction } from '@/services/Firebase'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'

const SHOULD_START_GUNSLINGER = window?.location?.host?.startsWith('old.') ? false : true

export default function MStartStopIterationCard({ iterationMeta }) {
  const { id, status, teamId, projectId, organizationId, vmStatus } = iterationMeta

  const [isChangingStatus, setIsChangingStatus] = useState(false)
  const { buttonColor, buttonIcon, buttonText } = useMemo(() => {
    switch (status) {
      case ITERATION_STATUSES.AWAITING:
        return {
          buttonColor: 'green',
          buttonIcon: <PlayIcon className="h-8 w-6" />,
          buttonText: 'Pause Iteration',
        }
      case ITERATION_STATUSES.RUNNING:
        return {
          buttonColor: 'red',
          buttonIcon: <StopIcon className="h-8 w-6" />,
          buttonText: 'Pause Iteration',
        }
      case ITERATION_STATUSES.FAILED:
        return {
          buttonColor: null,
          buttonIcon: null,
          buttonText: null,
        }
      case ITERATION_STATUSES.PAUSED:
        return {
          buttonColor: 'green',
          buttonIcon: <PlayIcon className="h-8 w-6" />,
          buttonText: 'Resume Iteration',
        }
      case ITERATION_STATUSES.NEW:
        return {
          buttonColor: 'red',
          buttonIcon: <StopIcon className="h-8 w-6" />,
          buttonText: 'Pause Iteration',
        }
      case ITERATION_STATUSES.RUN_REQUESTED:
        return {
          buttonColor: 'red',
          buttonIcon: <StopIcon className="h-8 w-6" />,
          buttonText: 'Pause Iteration',
        }

      default:
        return { buttonColor: null, buttonIcon: null, buttonText: null }
    }
  }, [status])

  const [fakedStatus, statusColor, statusIcon] = useMemo(() => {
    switch (status) {
      case ITERATION_STATUSES.AWAITING:
        return [
          'Awaiting',
          'zinc',
          <PlaneTakeoff key="planetakeoff-icon" className="h-6 w-5 text-zinc-700" />,
        ]
      case ITERATION_STATUSES.RUN_REQUESTED:
        return [
          'Awaiting to start',
          'zinc',
          <PlaneTakeoff key="planetakeoff-icon" className="h-6 w-5 text-zinc-700" />,
        ]
      case ITERATION_STATUSES.NEW:
        return [
          'Awaiting to start',
          'zinc',
          <PlaneTakeoff key="planetakeoff-icon" className="h-6 w-5 text-zinc-700" />,
        ]
      case ITERATION_STATUSES.RUNNING:
        return ['Running', 'blue', <Rocket key="plane-icon" className="h-6 w-5 text-blue-700" />]
      case ITERATION_STATUSES.PAUSED:
        return [
          'Paused',
          'yellow',
          <Construction key="pause-icon" className="h-6 w-5 text-yellow-600" />,
        ]
      case ITERATION_STATUSES.DONE:
        return [
          'Done',
          'green',
          <BadgeCheck key="planelanding-icon" className="h-6 w-5 text-emerald-600" />,
        ]
      case ITERATION_STATUSES.FAILED:
        return [
          'Failed',
          'zinc',
          <Construction key="pause-icon" className="h-6 w-5 text-yellow-600" />,
        ]
      default:
        return [status, 'zinc', <Rocket key="plane-icon" className="h-6 w-5 text-sky-700" />]
    }
  }, [status])

  const command = useMemo(() => {
    return ITERATION_STATUS_TO_COMMAND[status]
  }, [status])

  const buttonTextWithLoading = useMemo(() => {
    if (isChangingStatus) {
      return 'Working...'
    }
    return buttonText
  }, [buttonText, isChangingStatus])

  const sendIterationCommand = useCallback(async () => {
    setIsChangingStatus(true)
    await createIterationCommandFirebaseFunction({
      iterationId: id,
      command,
      dontStartGunslinger: !SHOULD_START_GUNSLINGER,
    })

    if (ITERATION_COMMANDS.PAUSE === command) {
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_PAUSE, {
        iterationId: id,
        projectId,
        teamId,
        organizationId,
        status,
        vmStatus,
      })
    } else if (ITERATION_COMMANDS.RESUME === command) {
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_RESUME, {
        iterationId: id,
        projectId,
        teamId,
        organizationId,
        status,
        vmStatus,
      })
    }
  }, [id, command, projectId, teamId, organizationId, status, vmStatus])

  if (status === ITERATION_STATUSES.DONE) {
    return null
  }

  if (status === ITERATION_STATUSES.FAILED) {
    return null
  }

  return (
    <div className="fixed bottom-6 right-6 rounded-lg bg-white p-4 shadow-2xl drop-shadow-md">
      <div className="flex items-center justify-center gap-3">
        <Badge color={statusColor} className="flex items-center justify-center">
          {statusIcon}
          {fakedStatus}
        </Badge>

        <Button color={buttonColor} className="cursor-pointer" onClick={sendIterationCommand}>
          {buttonIcon}
          {buttonTextWithLoading}
        </Button>
      </div>
    </div>
  )
}

MStartStopIterationCard.propTypes = {
  iterationMeta: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    awaitingCommand: PropTypes.object,
    teamId: PropTypes.string,
    projectId: PropTypes.string,
    organizationId: PropTypes.string,
    vmStatus: PropTypes.string,
  }).isRequired,
}
