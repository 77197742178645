import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { ArrowLeft } from 'lucide-react'
import { IterationFormData } from '@/components/molecules/iteration-details/CreateIteration/types'
import { Separator } from '@/components/ui/separator'
import { Badge } from '@/components/ui/badge'
import { Textarea } from '@/components/ui/textarea'
import { stackTemplate } from '@/const/stackTemplates'

export const BriefStep = ({
  formValues,
  prompt,
  onBack,
  onSubmit,
  submittingInProgress,
}: {
  formValues: IterationFormData
  prompt: string
  onBack: () => void
  onSubmit: () => void
  submittingInProgress: boolean
}) => {
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

  let newPrompt = `Let's build a POC for a new prospect, ${formValues.prospectName}.
Their website is ${formValues.prospectWebsite}`
  if (stackTemplate[formValues.technologies].promptExtras != null) {
    newPrompt += `\n\n${stackTemplate[formValues.technologies].promptExtras}`
  }

  return (
    <div className="mx-auto flex w-full flex-col space-y-6 p-6 text-2xl font-bold text-gray-800">
      <h1 className="  ">Proof of Concept for {formValues.prospectName || 'Unnamed Prospect'}</h1>
      <div className="space-y-6 overflow-y-auto">
        <div className="space-y-6 text-sm">
          <Separator className="my-2" />
          <div className=" flex items-center">
            <span className="w-24 font-semibold text-gray-600">Prospect:</span>
            <span className="ml-2">{formValues.prospectName || 'Not provided'}</span>
          </div>
          <Separator className="my-2" />
          <div className="flex items-center">
            <span className="w-24 font-semibold text-gray-600">Website:</span>
            <span className="ml-2">{formValues.prospectWebsite || 'Not provided'}</span>
          </div>
          <Separator className="my-2" />
          <Separator className="my-2" />
          <div className="flex items-center">
            <span className="w-24 font-semibold text-gray-600">Date:</span>
            <span className="ml-2">{currentDate}</span>
          </div>
          <Separator className="my-2" />
        </div>

        <div>
          <h2 className="mb-2 text-lg font-bold text-gray-800">Technology</h2>
          <div className="space-y-2 text-sm">
            {formValues.technologies && formValues.technologies.length > 0 ? (
              <>
                <div key={formValues.technologies} className="flex items-center space-x-2">
                  <Badge variant="outline" className="">
                    {formValues.technologies}
                  </Badge>
                </div>
              </>
            ) : (
              <p>Not specified</p>
            )}
          </div>
          {formValues.technicalRequirements && (
            <div>
              <p className="mt-4 text-sm text-gray-600">{formValues.technicalRequirements}</p>
            </div>
          )}
        </div>

        <div>
          <h2 className="mb-2 text-lg font-bold text-gray-800">Basic Use Case</h2>
          <Textarea
            value={newPrompt}
            placeholder="Edit or add more to your prompt..."
            className="flex-grow resize-none overflow-y-auto border-0 !border-none outline-none focus:border-0 focus:!border-none focus:outline-none focus:ring-0"
            readOnly={true}
            rows={10}
          />
        </div>

        {formValues.prospectRequirements && (
          <p className="text-sm text-gray-600">{formValues.prospectRequirements}</p>
        )}
      </div>
      <div className="mt-6 flex items-center justify-between border-t pt-4">
        <Button
          type="button"
          onClick={onBack}
          className="border border-gray-300 bg-white text-gray-700 hover:bg-gray-50"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Back
        </Button>
        <Button
          type="button"
          onClick={onSubmit}
          className="bg-blue-600 text-white hover:bg-blue-700"
          disabled={submittingInProgress}
        >
          {submittingInProgress ? 'Creating iteration...' : 'Submit'}
        </Button>
      </div>
    </div>
  )
}
