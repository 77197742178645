import PropTypes from 'prop-types'
import MActionListItem from '@/components/molecules/iteration-details/MActionListItem.jsx'
import { actionShape } from '@/components/propTypes.js'
import { useSearchParams } from 'react-router-dom'
import { safeParseInt } from '@/lib/params-utils.js'
import { useEffect, useMemo, useRef } from 'react'

/** @typedef {{
 content: string,
 title: string,
 detailsContent: string,
 }} ActionResultsDisplay */

export default function MActionsList({ actions = [], showAllActionsInStep = false }) {
  const [searchParams] = useSearchParams()
  const selectedActionIndex = safeParseInt(searchParams.get('action'))
  const listRef = useRef(null)

  useEffect(() => {
    if (selectedActionIndex !== null && listRef.current) {
      const selectedElement = listRef.current.querySelector(`[data-index="${selectedActionIndex}"]`)
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [])

  const actionsToDisplay = useMemo(() => {
    if (showAllActionsInStep) {
      return actions
    } else {
      return actions.slice(0, 3)
    }
  }, [actions, showAllActionsInStep])

  return (
    <div ref={listRef} className="flex  flex-col items-start justify-start gap-4 self-stretch ">
      {actionsToDisplay?.map((action, index) => (
        <MActionListItem
          key={`${action.actionId}-${action?.outputs?.length || 0}`}
          action={action}
          isLast={index === actions.length - 1}
        />
      ))}
    </div>
  )
}

MActionsList.propTypes = {
  //make array of action shapes
  actions: PropTypes.arrayOf(actionShape),
}
