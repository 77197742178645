import { DateTime } from 'luxon'

function formatIterationDate(dateInput, defaultValue = '') {
  if (!dateInput) {
    return defaultValue
  }

  let date

  if (typeof dateInput.toDate === 'function') {
    // Handle Firestore Timestamp object
    date = DateTime.fromJSDate(dateInput.toDate())
  } else if (
    typeof dateInput === 'object' &&
    '_seconds' in dateInput &&
    '_nanoseconds' in dateInput
  ) {
    // Handle plain object with _seconds and _nanoseconds
    const { _seconds, _nanoseconds } = dateInput
    if (typeof _seconds !== 'number' || typeof _nanoseconds !== 'number') {
      return defaultValue
    }
    const milliseconds = _seconds * 1000 + _nanoseconds / 1000000
    date = DateTime.fromMillis(milliseconds)
  } else {
    // Invalid input
    return defaultValue
  }

  return date.isValid ? date.toFormat('LLL d, yyyy h:mm a') : defaultValue
}

function calculateDuration(start, end) {
  // Convert start and end to DateTime objects
  const startDate = convertToDateTime(start)
  const endDate = convertToDateTime(end)

  if (!startDate.isValid || !endDate.isValid) {
    return 'Invalid date input'
  }

  // Calculate the duration
  const duration = endDate.diff(startDate)

  // Format the duration based on its length
  if (duration.as('hours') >= 1) {
    const hours = Math.floor(duration.as('hours'))
    const minutes = Math.floor(duration.as('minutes') % 60)
    return `${hours}:${minutes.toString().padStart(2, '0')} hr`
  } else {
    const minutes = Math.floor(duration.as('minutes'))
    const seconds = Math.floor(duration.as('seconds') % 60)
    return `${minutes}:${seconds.toString().padStart(2, '0')} min`
  }
}

function convertToDateTime(dateInput) {
  if (typeof dateInput.toDate === 'function') {
    // Handle Firestore Timestamp object
    return DateTime.fromJSDate(dateInput.toDate())
  } else if (
    typeof dateInput === 'object' &&
    '_seconds' in dateInput &&
    '_nanoseconds' in dateInput
  ) {
    // Handle plain object with _seconds and _nanoseconds
    const { _seconds, _nanoseconds } = dateInput
    const milliseconds = _seconds * 1000 + _nanoseconds / 1000000
    return DateTime.fromMillis(milliseconds)
  } else {
    // Invalid input
    return DateTime.invalid('Invalid input')
  }
}

export { formatIterationDate, calculateDuration }
