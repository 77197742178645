import PropTypes from 'prop-types'

const CommercetoolsIcon = ({ className = '', ...props }) => {
  return (
    <svg viewBox="300 100 300 300" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fill="#6359ff"
          d="M328.48,274v-122.2c0-3.38,3.66-5.5,6.59-3.81l105.82,61.1c2.72,1.57,4.39,4.47,4.39,7.61v122.2c0,3.38-3.66,5.5-6.59,3.81l-105.82-61.1c-2.72-1.57-4.39-4.47-4.39-7.61Z"
        />
        <path
          fill="#ffc806"
          d="M342.22,129.92l104.17-60.14c2.72-1.57,6.07-1.57,8.79,0l104.17,60.14c3.66,2.11,3.66,7.4,0,9.51l-104.17,60.14c-2.72,1.57-6.07,1.57-8.79,0l-104.17-60.14c-3.66-2.11-3.66-7.4,0-9.51Z"
        />
        <path
          fill="#0bbfbf"
          d="M456.28,338.9v-113.32c0-3.38,3.66-5.5,6.59-3.81l96.49,55.71c3.66,2.11,3.66,7.4,0,9.51l-96.49,55.71c-2.93,1.69-6.59-.42-6.59-3.81Z"
        />
      </g>
    </svg>
  )
}

CommercetoolsIcon.propTypes = {
  className: PropTypes.string,
  // You can add more specific PropTypes for other SVG attributes if needed
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default CommercetoolsIcon
