import PropTypes from 'prop-types'

const ShopifyIcon = ({ className = '', ...props }) => {
  return (
    <svg
      width="36"
      height="40"
      viewBox="0 0 36 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g id="Shopify.com_Symbol_12 1" clipPath="url(#clip0_779_854)">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.2158 39.2935L25.1266 5.01926C24.8958 4.78841 24.4455 4.85812 24.2695 4.90955L23.0969 5.27298C22.9522 4.7967 22.7643 4.3346 22.5358 3.89241C21.7038 2.30384 20.4878 1.46384 19.0158 1.46155H19.0112C18.9129 1.46155 18.8158 1.47069 18.7175 1.47869L18.706 1.47984C18.6635 1.4274 18.6193 1.37633 18.5735 1.32669C17.9335 0.640979 17.1106 0.306122 16.1255 0.334693C14.226 0.38955 12.3346 1.76326 10.7998 4.20326C9.71976 5.92098 8.90033 8.07755 8.66605 9.74726L4.92548 10.9073C3.82491 11.2535 3.79062 11.2878 3.64548 12.3267C3.53691 13.113 0.655762 35.4204 0.655762 35.4204L24.8021 39.601L25.2158 39.2935ZM17.3049 1.90155C16.9838 1.69012 16.6078 1.59184 16.162 1.60098C13.2295 1.68555 10.6718 6.27184 10.0192 9.32784L12.8283 8.45698L13.33 8.30155C13.698 6.36098 14.6215 4.34726 15.8295 3.05126C16.2519 2.58687 16.7505 2.19804 17.3038 1.90155H17.3049ZM14.7186 7.87069L18.7506 6.62041C18.7632 5.56898 18.6489 4.01469 18.1232 2.91755C17.5632 3.14841 17.0923 3.55298 16.754 3.91526C15.8478 4.89012 15.1083 6.37698 14.7198 7.87069H14.7186ZM20.0135 6.22955L21.8878 5.64784C21.5883 4.67412 20.8775 3.04212 19.4363 2.76784C19.8843 3.92555 19.9998 5.26612 20.0135 6.22955Z"
          fill="#95BF47"
        />
        <path
          id="Vector_2"
          d="M30.6262 7.56891C30.4822 7.55748 27.6914 7.51405 27.6914 7.51405C27.6914 7.51405 25.3565 5.24434 25.1257 5.01234C25.0363 4.92985 24.9231 4.87783 24.8022 4.86377V39.5952L35.2651 36.9906C35.2651 36.9906 30.9954 8.08663 30.968 7.88891C30.9548 7.80495 30.9138 7.72782 30.8517 7.66982C30.7895 7.61183 30.7109 7.5763 30.6262 7.56891Z"
          fill="#5E8E3E"
        />
        <path
          id="Vector_3"
          d="M19.0032 12.9623L17.7872 17.5154C17.7872 17.5154 16.4317 16.8971 14.8237 16.9988C12.4672 17.1474 12.442 18.6366 12.466 19.0103C12.594 21.0446 17.9437 21.4903 18.2443 26.2594C18.4809 30.0114 16.2569 32.5794 13.0523 32.7806C9.20658 33.024 7.08887 30.752 7.08887 30.752L7.90372 27.28C7.90372 27.28 10.0352 28.8903 11.7403 28.7817C12.8557 28.712 13.2534 27.8046 13.2134 27.1623C13.0454 24.5074 8.68887 24.6628 8.41344 20.2994C8.18258 16.6251 10.5906 12.9051 15.906 12.5691C17.954 12.4388 19.0032 12.9634 19.0032 12.9634"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_779_854">
          <rect width="35.4286" height="40" fill="white" transform="translate(0.285645)" />
        </clipPath>
      </defs>
    </svg>
  )
}

ShopifyIcon.propTypes = {
  className: PropTypes.string,
  // You can add more specific PropTypes for other SVG attributes if needed
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ShopifyIcon
