import {
  CpuChipIcon,
  ChatBubbleBottomCenterTextIcon,
  ChatBubbleLeftRightIcon,
  ServerStackIcon,
} from '@heroicons/react/24/outline'
import { UsersIcon, SunIcon, UserIcon, LightBulbIcon } from '@heroicons/react/24/outline'

function MIterationHeaderCostsSkeleton() {
  return (
    <div className="bg-white">
      <h2 className="sr-only">Cost summary</h2>
      <div className="h-full rounded-lg shadow-md ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap pb-6">
          <div className="flex-auto pl-6 pt-6">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              <div className="h-[1em] w-44 animate-pulse rounded-full bg-zinc-300 " />
            </dt>
            <dd className="mt-1 font-mono text-base font-semibold leading-6 text-gray-900">
              <div className="h-[1em] w-28 animate-pulse rounded-full bg-zinc-200 " />
            </dd>
          </div>
          <div className="flex flex-1 flex-col items-end justify-end px-6">
            <div className="flex w-full items-center justify-end pb-2">
              <CpuChipIcon className="h-5 w-5" />
            </div>

            <dt className="sr-only">Status</dt>

            <dd className="inline-flex animate-pulse items-center rounded-md bg-zinc-50 px-2 py-1 text-xs font-medium text-zinc-700 ring-1 ring-inset ring-zinc-600/20">
              <div className="h-[1rem] w-12 animate-pulse rounded-full bg-zinc-200 " />
            </dd>
          </div>
          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6 ">
            <dt className="flex-none">
              <span className="sr-only">Input Tokens Used</span>
              <ChatBubbleBottomCenterTextIcon
                className="h-6 w-5 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd className="flex w-full items-center justify-between text-sm font-medium leading-6 text-gray-900">
              <span>
                <div className="h-[1rem] w-28 animate-pulse rounded-full bg-zinc-200 " />
              </span>{' '}
              <span className="text-right font-mono text-gray-600">
                <div className="h-[1rem] w-14 animate-pulse rounded-full bg-zinc-200 " />
              </span>
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Output Tokens Used</span>
              <ChatBubbleLeftRightIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="flex w-full items-center justify-between text-sm font-medium leading-6 text-gray-900">
              <span>
                <div className="h-[1rem] w-28 animate-pulse rounded-full bg-zinc-200 " />
              </span>{' '}
              <span className="text-right font-mono text-gray-600">
                <div className="h-[1rem] w-14 animate-pulse rounded-full bg-zinc-200 " />
              </span>
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Computing Time Used</span>
              <ServerStackIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="flex w-full items-center justify-between text-sm font-medium leading-6 text-gray-900">
              <span>
                <div className="h-[1rem] w-28 animate-pulse rounded-full bg-zinc-200 " />
              </span>{' '}
              <span className="text-right font-mono text-gray-600">
                <div className="h-[1rem] w-14 animate-pulse rounded-full bg-zinc-200 " />
              </span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}

function MIterationHeaderDevEstimatesSkeleton() {
  return (
    <div className="bg-white">
      <h2 className="sr-only">Cost summary</h2>
      <div className="h-full rounded-lg shadow-md ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap pb-6">
          <div className="flex-auto pl-6 pt-6">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              <div className="h-[1rem] w-44 animate-pulse rounded-full bg-zinc-300 " />
            </dt>
            <dd className="mt-1 font-mono text-base font-semibold leading-6 text-gray-900">
              <div className="h-[1rem] w-28 animate-pulse rounded-full bg-zinc-200 " />
            </dd>
          </div>
          <div className="flex flex-1 flex-col items-end justify-end px-6">
            <div className="flex w-full items-center justify-end pb-2">
              <UsersIcon className="h-5 w-5" />
            </div>

            <dt className="sr-only">Status</dt>

            <dd className="inline-flex animate-pulse items-center rounded-md bg-zinc-50 px-2 py-1 text-xs font-medium text-zinc-700 ring-1 ring-inset ring-zinc-600/20">
              <div className="h-[1rem] w-12 animate-pulse rounded-full bg-zinc-200 " />
            </dd>
          </div>

          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
            <dt className="flex-none ">
              <span className="sr-only">Senior developer estimate</span>
              <SunIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="flex w-full items-center justify-between text-sm font-medium leading-6 text-gray-900">
              <span>
                <div className="h-[1rem] w-28 animate-pulse rounded-full bg-zinc-200 " />
              </span>{' '}
              <span className="text-right font-mono text-gray-600">
                <div className="h-[1rem] w-14 animate-pulse rounded-full bg-zinc-200 " />
              </span>
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Mid-level developer estimate</span>
              <LightBulbIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="flex w-full items-center justify-between text-sm font-medium leading-6 text-gray-900">
              <span>
                <div className="h-[1rem] w-28 animate-pulse rounded-full bg-zinc-200 " />
              </span>{' '}
              <span className="text-right font-mono text-gray-600">
                <div className="h-[1rem] w-14 animate-pulse rounded-full bg-zinc-200 " />
              </span>
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Junior developer estimate</span>
              <UserIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="flex w-full items-center justify-between text-sm font-medium leading-6 text-gray-900">
              <span>
                <div className="h-[1rem] w-28 animate-pulse rounded-full bg-zinc-200 " />
              </span>{' '}
              <span className="text-right font-mono text-gray-600">
                <div className="h-[1rem] w-14 animate-pulse rounded-full bg-zinc-200 " />
              </span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}

export default function OIterationDetailsSkeleton() {
  return (
    <>
      <div className="grid grid-flow-row grid-cols-2 gap-8 pb-6"></div>
      <div className="mb-6 flex w-full flex-col  rounded-md bg-white p-6  shadow-md ">
        <div className="h-[1rem] w-full animate-pulse rounded-full bg-zinc-300 font-mono text-xs font-semibold uppercase leading-6 tracking-widest text-zinc-800"></div>
        <div className="flex flex-col text-pretty pt-2 font-mono text-zinc-800">
          <div className="mb-2 h-[1rem] w-full animate-pulse rounded-full bg-zinc-300 " />
          <div className="mb-2 h-[1rem] w-full animate-pulse rounded-full bg-zinc-300 " />
          <div className="mb-2 h-[1rem] w-72 animate-pulse rounded-full bg-zinc-300 " />
        </div>
      </div>
      {/* <MElementsList2
        elements={elementsWithRelativeTime}
        expandAllDetails={SHOW_ALL_DETAILS_BY_DEFAULT}
      />
      <div className="mb-24 mt-6">
        <Button
          disabled={!canCreateNewIteration}
          onClick={() => {
            setIsNewIterationDialogOpen(true)
          }}
          className={!canCreateNewIteration ? 'bg-zinc-600 hover:cursor-wait' : ''}
        >
          {!canCreateNewIteration ? 'Working....' : 'Create new iteration from this point'}
        </Button>
        </div >

       */}
    </>
  )
}
