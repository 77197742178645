import PropTypes from 'prop-types'

const SapCommerceIcon = ({ className = '', ...props }) => {
  return (
    <svg
      width="56"
      height="28"
      viewBox="0 0 56 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g id="SAP_2011_logo 1" clipPath="url(#clip0_779_887)">
        <path id="Vector" d="M56 0.153809H0V27.8461H28.3077" fill="url(#paint0_linear_779_887)" />
        <path
          id="Vector_2"
          d="M27.6921 5.69247V18.7386L22.8921 5.69247H18.0921L14.0306 16.5232C13.5383 13.8155 10.7075 12.8309 8.49213 12.0925C7.0152 11.6002 5.4152 10.9848 5.4152 10.1232C5.4152 9.50786 6.27674 8.89247 7.87674 9.01555C8.98443 9.13863 9.96905 9.13863 11.9383 10.1232L13.9075 6.80017C12.0614 5.81555 9.59982 5.32324 7.63059 5.32324C5.29213 5.32324 3.3229 6.0617 2.09213 7.29247C1.23059 8.15401 0.861358 9.2617 0.738281 10.4925C0.738281 12.2155 1.35367 13.4463 2.70751 14.4309C3.8152 15.1694 5.16905 15.6617 6.39982 16.0309C7.99982 16.5232 9.23059 17.0155 9.23059 17.8771C9.10751 18.9848 7.87674 19.2309 7.0152 19.2309C5.53828 19.354 4.30751 19.1079 2.46136 18.0002L0.738281 21.3232C2.58443 22.4309 4.55367 22.9232 6.89213 22.9232C8.49213 22.9232 10.9537 22.4309 12.3075 21.2002L12.0614 22.3079H16.7383L17.5998 20.2155C19.446 20.7079 21.5383 20.8309 23.3844 20.2155L23.9998 22.3079H32.369V17.3848H34.0921C38.3998 17.3848 40.9844 15.2925 40.9844 11.6002C40.9844 7.41555 38.5229 5.69247 33.2306 5.69247H27.6921ZM18.7075 16.5232L20.4306 10.9848H20.5537L22.2767 16.5232C21.046 16.8925 19.8152 16.8925 18.7075 16.5232ZM32.369 13.6925V9.2617H33.5998C35.1998 9.2617 36.4306 9.75401 36.4306 11.354C36.4306 13.0771 35.1998 13.6925 33.5998 13.6925H32.369Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_779_887"
          x1="0"
          y1="0.153809"
          x2="0"
          y2="27.8461"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00AAEE" />
          <stop offset="1" stopColor="#0055AA" />
        </linearGradient>
        <clipPath id="clip0_779_887">
          <rect width="56" height="27.6923" fill="white" transform="translate(0 0.153809)" />
        </clipPath>
      </defs>
    </svg>
  )
}

SapCommerceIcon.propTypes = {
  className: PropTypes.string,
  // You can add more specific PropTypes for other SVG attributes if needed
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default SapCommerceIcon
