import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { ContactIcon, Rocket } from 'lucide-react'
import { TECH_STACKS } from './ChooseTechStackStep/ChooseTechStackStep'
import { Button } from '@/components/ui/button'
import LululemonIcon from '@/assets/svg-components/stacks/LululemonIcon'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/const/routes'
import { USECASE_ID } from '@/const/stackTemplates'

export function BuildPoCStep() {
  const navigate = useNavigate()
  const techStackData = TECH_STACKS.find(ts => ts.id === USECASE_ID.SHOPIFY_HYDROGEN)

  return (
    <div className="space-y-6">
      <Card className="w-full p-8 shadow-none">
        <CardContent className="grid w-full grid-cols-2 gap-3 p-0">
          <Card className="rounded-md p-4 shadow-none">
            <CardHeader className="p-0 font-normal">
              <CardTitle className="flex items-center justify-between text-xs text-base-muted-foreground">
                <div>Prospect</div>
                <ContactIcon className="size-3" />
              </CardTitle>
            </CardHeader>
            <CardContent className="mt-3 flex items-center gap-3 p-0">
              <LululemonIcon className="size-8" />
              <div>Lululemon</div>
            </CardContent>
          </Card>
          <Card className="rounded-md p-4 shadow-none">
            <CardHeader className="p-0 font-normal">
              <CardTitle className="flex items-center justify-between text-xs text-base-muted-foreground">
                <div>Technology</div>
                <ContactIcon className="size-3" />
              </CardTitle>
            </CardHeader>
            <CardContent className="mt-3 flex items-center gap-3 p-0">
              {techStackData.icon}
              <div>{techStackData.title}</div>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
      <Button
        className="h-11 w-full bg-base-foreground"
        onClick={() => navigate(ROUTES.ONBOARDING_BUILDING)}
      >
        Build <Rocket className="ml-2 size-4" />
      </Button>
    </div>
  )
}
