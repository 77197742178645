import { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'

import { Button } from '@/components/catalyst/button'
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components/catalyst/dialog'

import { FieldGroup } from '@/components/catalyst/fieldset'

import { useToast } from '@/components/ui/use-toast'

import { updateTeam } from '@/services/Firebase'
import MTeamForm from '@/components/molecules/MTeamForm.jsx'
import { useMutation } from '@/lib/hooks.js'

export default function MUpdateTeamDialog({
  isOpen,
  onClose,
  onCompleted = () => {},
  initialValues,
}) {
  const {
    response,
    mutationFn: updateTeamFn,
    error: teamUpdateError,
    isMutating: isUpdating,
    resetFn: resetUpdateTeamFn,
  } = useMutation({ mutationCallback: updateTeam })
  //
  const { toast } = useToast()

  const defaultValues = useMemo(() => {
    return {
      team: initialValues,
    }
  }, [])

  const methods = useForm({
    defaultValues: defaultValues,
  })
  const { handleSubmit, reset: resetForm } = methods

  const handleClose = useCallback(() => {
    resetForm()
    onClose()
  }, [onClose, resetForm])

  useEffect(() => {
    if (response) {
      toast({
        title: 'Team updated successfully! 🎉',
        description: '',
      })
      onCompleted(response?.data || {})
      resetUpdateTeamFn()
      handleClose()
    }
    if (teamUpdateError) {
      toast({
        variant: 'destructive',
        title: 'Error updating a team 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error updating team:', teamUpdateError)
      resetUpdateTeamFn()
    }
  }, [response, teamUpdateError, toast, resetUpdateTeamFn, handleClose, onCompleted])

  const onSubmit = useCallback(
    data => {
      if (!isUpdating) {
        updateTeamFn({
          ...data?.team,
        })
      }
    },
    [updateTeamFn, isUpdating]
  )

  return (
    <>
      <Dialog size="3xl" open={isOpen} onClose={handleClose}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Update team {initialValues.name}</DialogTitle>
            <DialogBody>
              <FieldGroup className="space-y-8 pt-8">
                <MTeamForm />
              </FieldGroup>
            </DialogBody>
            <DialogActions>
              <Button disabled={isUpdating} plain onClick={handleClose}>
                Cancel
              </Button>
              <Button
                disabled={isUpdating}
                className={isUpdating ? 'bg-zinc-600' : ''}
                type="submit"
              >
                {isUpdating ? 'Working...' : 'Update Team'}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  )
}

MUpdateTeamDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
}
