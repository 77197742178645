import React, { createContext, useContext, useState } from 'react'

interface OnboardingContextValue {
  currentStepIndex: number
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>
  selectedTechStacks: string[] | null
  setSelectedTechStacks: React.Dispatch<React.SetStateAction<string[] | null>>
}

const OnboardingContext = createContext<OnboardingContextValue | undefined>(undefined)

export function useOnboardingContext() {
  const context = useContext(OnboardingContext)
  if (!context) {
    throw new Error('useOnboardingContext must be used within an OnboardingProvider')
  }
  return context
}

export function OnboardingProvider({ children }: { children: React.ReactNode }) {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
  const [selectedTechStacks, setSelectedTechStacks] = useState<string[] | null>(null)

  return (
    <OnboardingContext.Provider
      value={{
        currentStepIndex,
        setCurrentStepIndex,
        selectedTechStacks: selectedTechStacks,
        setSelectedTechStacks: setSelectedTechStacks,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )
}
