import { IterationFormData } from '@/components/molecules/iteration-details/CreateIteration/types'
import { helpContentWithExamples } from '@/components/molecules/project-details/CreateProject/helpContent'

export const HelpContent = ({
  selectedField,
  onExampleClick,
}: {
  selectedField: keyof IterationFormData | null
  onExampleClick: (field: string, value: string) => void
}) => {
  const content = selectedField
    ? helpContentWithExamples[selectedField]
    : helpContentWithExamples['prospectName']

  return (
    <div className="h-full max-w-[450px] overflow-y-auto break-words bg-gray-100 p-6">
      <h3 className="mb-2 text-lg font-semibold">{content.title ?? 'Help Guide'}</h3>
      {content ? (
        <>
          <div
            className="mb-4 w-[442px] max-w-[442px] break-words text-sm text-gray-600"
            style={{ width: '390px' }}
          >
            {content.text}
          </div>
          {content.examples.length > 0 && (
            <div>
              <h4 className="mb-2 text-sm font-semibold">Example Values:</h4>
              <ul className="list-disc space-y-2 pl-5">
                {content.examples.map((example, index) => (
                  <li key={index} className="text-sm">
                    <button
                      onClick={() => onExampleClick(selectedField, example)}
                      className="text-blue-600 hover:underline focus:outline-none"
                    >
                      {example}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      ) : (
        <p className="mb-4 w-full break-words text-sm text-gray-600" style={{ width: '390px' }}>
          Select a field in the form to see specific help content and examples
        </p>
      )}
    </div>
  )
}
