import { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'

import { Button } from '@/components/catalyst/button'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '@/components/catalyst/dialog'

import { Description, FieldGroup } from '@/components/catalyst/fieldset'

import { useToast } from '@/components/ui/use-toast'

import { createOrganization } from '@/services/Firebase'
import MOrganizationForm from '@/components/molecules/MOrganizationForm.jsx'
import MTeamForm from '@/components/molecules/MTeamForm.jsx'
import TemplateForm from '@/components/molecules/MTemplateForm.jsx'

function useCreateOrganization() {
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  /**
   * Create a new organization.
   * @param {Object} options - The options for the function.
   * @param {String} options.name - The name of the organization.
   * @param {String} options.organization - The description of the organization.

   * @returns {Promise<Object>} A promise that resolves when the organization is created.
   */
  const createOrganizationFn = async payload => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    setError(null)
    try {
      const response = await createOrganization(payload)
      setResponse(response)
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsCreating(false)
    }
  }

  function resetCreateOrganizationFn() {
    setIsCreating(false)
    setError(null)
    setResponse(null)
  }

  return {
    response,
    isCreating,
    error,
    createOrganizationFn,
    resetCreateOrganizationFn,
  }
}

/**
 * A dialog for creating a new organization.
 * @param {Object} props - The props for the component.
 * @param {Boolean} props.isOpen - Whether the dialog is open.
 * @param {Function} props.onClose - A function to close the dialog.
 * @param {Function} props.onOrganizationCreated - A function to call when the organization is created.
 * @returns {JSX.Element} The JSX element for the component.
 *
 */
export default function MCreateOrganizationDialog({
  isOpen,
  onClose,
  onOrganizationCreated = () => {},
}) {
  const {
    response,
    createOrganizationFn,
    error: organizationCreationError,
    isCreating,
    resetCreateOrganizationFn,
  } = useCreateOrganization()
  //
  const { toast } = useToast()

  const defaultValues = useMemo(() => {
    return {
      organization: {
        name: '',
        description: '',
      },
      team: { name: '', description: '' },

      // template: {
      //   name: '',
      //   description: '',
      //   environmentVariables: [],
      //   usecase: {
      //     usecaseId: '',
      //     toolsets: '',
      //   },
      // },
    }
  }, [])

  const methods = useForm({
    defaultValues: defaultValues,
  })
  const { handleSubmit, reset: resetForm } = methods

  const handleClose = useCallback(() => {
    resetForm()
    onClose()
  }, [onClose, resetForm])

  // handle post organization creation / error and closing the dialog
  useEffect(() => {
    if (response) {
      toast({
        title: 'Organization created successfully! 🎉',
        description: '',
      })
      onOrganizationCreated(response?.data || {})
      resetCreateOrganizationFn()
      handleClose()
    }
    if (organizationCreationError) {
      toast({
        variant: 'destructive',
        title: 'Error creating organization 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error creating organization:', organizationCreationError)
      resetCreateOrganizationFn()
    }
  }, [
    response,
    organizationCreationError,
    toast,
    resetCreateOrganizationFn,
    handleClose,
    onOrganizationCreated,
  ])

  // prepare form data for organization to be created
  const onSubmit = useCallback(
    data => {
      if (!isCreating) {
        createOrganizationFn({
          ...data?.organization,
          team: data?.team,
          // defaultTemplate: {
          //   name: data?.template.name,
          //   description: data?.template.description,
          // iterationDefaultsTemplate: {
          //   environment: { fields: data?.template.environmentVariables },
          //   usecase: {
          //     fields: [
          //       { key: 'usecaseId', value: data?.template?.usecase?.usecaseId },
          //       { key: 'toolsets', value: data?.template?.usecase?.toolsets },
          //     ],
          //   },
          // },
          // },
        })
      }
    },
    [createOrganizationFn, isCreating]
  )

  return (
    <>
      <Dialog size="3xl" open={isOpen} onClose={handleClose}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Create new organization</DialogTitle>
            <DialogDescription>
              Create a new organization. You will be able to add teams and projects to it.
            </DialogDescription>
            <DialogBody>
              <FieldGroup className="space-y-8 pt-8">
                <MOrganizationForm />
              </FieldGroup>

              <Description className=" mt-8 border-b border-zinc-200 pb-2 font-mono font-bold text-zinc-700">
                Add the first team for this organization
              </Description>
              <FieldGroup className="space-y-8 pt-8">
                <MTeamForm />
              </FieldGroup>

              {/*<Description className=" mt-8 border-b border-zinc-200 pb-2 font-mono font-bold text-zinc-700">*/}
              {/*  Add the first project template for this organization*/}
              {/*</Description>*/}
              {/*<TemplateForm />*/}
            </DialogBody>
            <DialogActions>
              <Button disabled={isCreating} plain onClick={handleClose}>
                Cancel
              </Button>
              <Button
                disabled={isCreating}
                className={isCreating ? 'bg-zinc-600' : ''}
                type="submit"
              >
                {isCreating ? 'Working...' : 'Create Organization'}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  )
}

MCreateOrganizationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOrganizationCreated: PropTypes.func,
}
