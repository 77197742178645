import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { CirclePlay } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal.jsx'

export default function RunDaemonAction({ action, isLast = false }) {
  let detailsContent = `## COMMENT:\n${action.args?.comment}\n\n## COMMAND:\n${action.args?.cmd || action.args?.command}\n\n## PORT:\n${action.args?.port}\n\n`
  const terminalOutputs = []

  if (action.outputs) {
    detailsContent += '## OUTPUTS:\n'
    action?.outputs?.forEach((output, index) => {
      // check if output is an object
      if (output && typeof output === 'object') {
        const outputEntries = Object.entries(output).map(([key, value]) => `${key}: ${value}`)
        outputEntries.forEach(entry => {
          detailsContent += `${entry}`
          if (index < 10) {
            terminalOutputs.push(`- ${entry}\n`)
            index === 9 && terminalOutputs.push('... see details to see full output...')
          }
        })
      }
    })
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={CirclePlay}
      isLast={isLast}
      title="Run daemon"
    >
      <AActionContentGenericLayout>
        <AActionContentDescription content={action?.args?.comment} />
        <AActionContentTerminal
          content={'$ ' + action?.args?.cmd}
          terminalOutputs={terminalOutputs}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

RunDaemonAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
