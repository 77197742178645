import PropTypes from 'prop-types'

const ShopwareIcon = ({ color = '#189EFF', className = '', ...props }) => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M32.8911 35.6364C31.1457 34.3273 28.6002 33.3455 25.873 32.2909C22.6366 31.0545 18.9639 29.6364 16.2366 27.4182C13.1093 24.9455 11.582 21.8182 11.582 17.8545C11.582 14.2909 13.0366 11.2727 15.8366 9.09091C18.9639 6.61818 23.6911 5.34545 29.5093 5.34545C31.1093 5.34545 32.673 5.45455 34.0911 5.63636C34.2002 5.63636 34.3457 5.6 34.382 5.45455C34.4548 5.34545 34.4184 5.2 34.3093 5.12727C30.673 1.81818 25.9093 0 20.9639 0C15.6184 0 10.6002 2.07273 6.81841 5.85455C3.03659 9.63636 0.963867 14.6545 0.963867 20C0.963867 25.3455 3.03659 30.3636 6.81841 34.1455C10.6002 37.9273 15.6184 40 20.9639 40C25.2911 40 29.4002 38.6545 32.8548 36.0727C32.9275 36 32.9639 35.9273 32.9639 35.8545C33.0002 35.7818 32.9639 35.7091 32.8911 35.6364Z"
        fill="#189EFF"
      />
      <path
        d="M40.8911 18.1455C40.8911 18.0727 40.8548 18 40.7821 17.9636C36.6366 14.5091 33.2548 13.0909 29.1457 13.0909C26.9639 13.0909 25.2911 13.5273 24.1639 14.4C23.1821 15.1636 22.673 16.1818 22.673 17.4182C22.673 20.8364 26.8911 22.4364 31.7275 24.2545C34.2366 25.2 36.8184 26.1455 39.2184 27.4909C39.2548 27.5273 39.2911 27.5273 39.3639 27.5273C39.4002 27.5273 39.4366 27.5273 39.473 27.4909C39.5457 27.4545 39.6184 27.4182 39.6548 27.3091C40.5639 24.9818 41.0366 22.5091 41.0366 20C41.0002 19.4182 40.9639 18.8 40.8911 18.1455Z"
        fill="#189EFF"
      />
    </svg>
  )
}

ShopwareIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  // You can add more specific PropTypes for other SVG attributes if needed
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ShopwareIcon
