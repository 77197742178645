import { Badge } from '@/components/ui/badge'
import { Card, CardContent } from '@/components/ui/card'
import gradientCheck from '@/assets/images/gradient-check.svg'
import { cn } from '@/lib/utils'

export interface TechStackCardProps {
  category: string
  title: string
  icon: React.ReactNode
  isComingSoon?: boolean
  isSelected: boolean
  onClick: () => void
}

export function TechStackCard({
  category,
  title,
  icon,
  isComingSoon,
  isSelected,
  onClick,
}: TechStackCardProps) {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center rounded-lg p-[2px]',
        isSelected && 'bg-gradient-orange'
      )}
    >
      <Card
        className={cn(
          'size-52 cursor-pointer rounded-md border-base-border shadow-none',
          isSelected && 'border-none shadow-lg'
          // isComingSoon && 'cursor-not-allowed'
        )}
        onClick={onClick}
      >
        <CardContent className="relative flex h-full flex-col items-center justify-center">
          {isSelected && (
            <div className="absolute left-2 top-2">
              <img src={gradientCheck} className="size-4" alt="check" />
            </div>
          )}
          <div className="absolute right-2 top-2 space-x-1">
            {category && (
              <Badge variant="outline" className="capitalize text-base-muted-foreground">
                {category}
              </Badge>
            )}
            {isComingSoon && (
              <Badge variant="outline" className="text-base-muted-foreground">
                Soon
              </Badge>
            )}
          </div>
          <div className="flex size-20 flex-col items-center justify-center rounded-md border border-base-border">
            {icon}
          </div>
          <div
            className={cn(
              'absolute top-36 w-3/4 text-center text-sm text-base-foreground',
              isComingSoon && 'text-base-muted-foreground'
            )}
          >
            {title}
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
