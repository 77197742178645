import React, { useCallback, useMemo, useState } from 'react'
import OPageWrapper from '@/components/organisms/OPageWrapper'
import useStore from '@/stores/useStore'

import OProjectsTable from '@/components/organisms/OProjectsTable'

import { Link } from '@/components/catalyst/link'
import MCardTableSkeleton from '@/components/molecules/MCardTableSkeleton'

import { ROUTES } from '@/const/routes'
import { CreateButton } from '@/components/atoms/ACreateButton.jsx'
import MCreateOrganizationDialog from '@/components/molecules/MCreateOrganizationDialog.jsx'
import MNewProjectDialog from '@/components/molecules/MCreateNewProject2.jsx'
import { Button } from '@/components/catalyst/button'
import { stackTemplate } from '@/const/stackTemplates.ts'
import { ITERATION_STATUSES } from '@/const/const.js'
import {
  CheckCheck,
  PauseCircle,
  PlaneTakeoff,
  TimerReset,
  XOctagon,
  Loader,
  TvMinimal,
  MoreVertical,
  Copy,
} from 'lucide-react'
import Logo from '@/assets/svg-components/Logo.jsx'
import colors from 'tailwindcss/colors.js'
import { getPreviewUrl } from '@/lib/git-utils.js'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics.js'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import MDuplicateProjectDialog from '@/components/molecules/project-details/CreateProject/MDuplicateProjectDialog.tsx'
import { useNavigate } from 'react-router-dom'

const BREADCRUMBS = [
  {
    name: 'Dashboard',
    href: ROUTES.HOME,
    current: true,
  },
]

export default function PHome() {
  const [isDuplicateProjectDialogOpen, setIsDuplicateProjectDialogOpen] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)
  const projects = useStore(state => state.projects)
  const organizations = useStore(state => state.organizations)
  const isAfterInitialLoad = useStore(state => state.isAfterInitialLoad)
  const isSuperAdminFn = useStore(state => state.isSuperAdmin)
  const userRoles = useStore(state => state.userRoles)
  const getProjectBrokenDownByOrganization = useStore(
    state => state.getProjectBrokenDownByOrganization
  )
  const handleDuplicateProject = project => {
    setSelectedProject(project)
    setIsDuplicateProjectDialogOpen(true)
  }

  const projectsByOrganization = useMemo(() => {
    if (projects && projects.length > 0 && organizations && organizations.length > 0) {
      return getProjectBrokenDownByOrganization()
    }
    return null
  }, [projects, getProjectBrokenDownByOrganization, organizations])

  const handleAddNewProject = useCallback(organization => {
    if (Object.keys(organization?.templates || {}).length === 0) {
      alert('No templates found for this organization')
    } else {
      setIsNewProjectDialogOpen(true)
      setSelectedOrganization(organization)
    }
  }, [])

  const isSuperAdmin = useMemo(() => {
    if (userRoles) {
      return isSuperAdminFn()
    } else {
      return false
    }
  }, [isSuperAdminFn, userRoles])

  if (!isAfterInitialLoad) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white/80">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-gray-200 border-t-blue-600" />
      </div>
    )
  }

  if (!projectsByOrganization || projectsByOrganization.length === 0) {
    return (
      <div className="mt-12 text-4xl font-bold tracking-tight text-gray-400 sm:text-6xl">
        No projects found
      </div>
    )
  }

  return (
    <>
      <div className="flex w-[800px] flex-col gap-8 py-8">
        {projectsByOrganization.map(organization => (
          <div
            key={organization.id}
            className="inline-flex w-full flex-col items-center justify-center gap-8 "
          >
            <div className="inline-flex w-full items-start justify-start gap-1">
              <div className="font-['PP Supply Sans'] text-3xl font-normal leading-10 text-stone-900">
                {projectsByOrganization.length === 1 ? 'Projects' : organization.name}
              </div>
              <div className="font-['PP Supply Sans'] text-sm font-normal leading-none text-stone-500">
                [{organization.projects.length}]
              </div>
            </div>

            <div className="flex w-full flex-col items-start justify-start gap-2">
              {organization.projects.map(project => {
                const TechnologyIcon = stackTemplate[project.projectConfiguration?.techStack]?.icon
                const IterationsTotalCount = project.iterations?.totalCount
                const lastIterationStatus = project.iterations?.lastIteration?.status
                const lastIterationPreview = project.iterations?.lastIteration?.preview_urls ?? ''
                const previewUrl = getPreviewUrl(lastIterationPreview)
                const [iterationStatus, statusColor, statusIcon] =
                  getIterationInfo(lastIterationStatus)
                return (
                  <div
                    key={project.id}
                    className="inline-flex w-full items-center justify-start gap-6 self-stretch rounded-lg border border-stone-200 bg-white px-3 py-6"
                  >
                    <div className="flex h-8 w-[324px] max-w-[324px] shrink grow basis-0 items-start justify-start gap-0.5">
                      <Link
                        className="font-['PP Supply Sans'] truncate text-lg font-normal leading-loose text-stone-900"
                        title={project.name}
                        href={ROUTES.PROJECT_DETAILS.replace(':projectId', project.id)}
                      >
                        {project.name}
                      </Link>
                      {/*<div className="font-['PP Supply Sans'] text-xs font-normal leading-none text-stone-500">*/}
                      {/*  {IterationsTotalCount && `[${IterationsTotalCount}]`}*/}
                      {/*</div>*/}
                    </div>
                    <div className="flex h-5 w-[160px] max-w-[160px] items-center justify-start gap-2">
                      <div className="font-['Inter'] text-sm font-normal leading-tight text-stone-500">
                        {project.projectConfiguration?.prospectName}
                      </div>
                    </div>
                    <div className="flex h-8 w-[28px] max-w-[28px] items-start justify-start">
                      <div className="flex items-center justify-center rounded-full border border-stone-200 bg-white p-2">
                        <div className="flex h-4 w-4 items-center justify-center px-px">
                          <div className="relative flex h-4 w-3.5 flex-col items-center justify-start">
                            {TechnologyIcon && <TechnologyIcon />}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex h-8 items-center justify-end gap-2">
                      <div className="flex w-[108px] items-center justify-end gap-2 rounded-md p-2">
                        <div
                          className={`font-['PP Supply Sans'] text-xs font-normal uppercase leading-none text-${statusColor}`}
                        >
                          {iterationStatus}
                        </div>
                        <div className="relative h-4 w-4">{statusIcon}</div>
                      </div>
                      <IterationPreview
                        previewUrl={previewUrl}
                        lastIterationStatus={lastIterationStatus}
                      />
                      {!project.is_reference && (
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <div className="flex h-8 w-8 items-center justify-center gap-2 rounded-md bg-stone-100 px-4 py-2">
                              <div className="flex h-4 w-4 items-center justify-center py-0.5">
                                <MoreVertical className="flex h-4 w-4 items-center justify-center py-0.5" />
                              </div>
                            </div>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuItem
                              onClick={() => {
                                handleDuplicateProject(project)
                              }}
                            >
                              <Copy className="mr-2 h-4 w-4" />
                              <span>Duplicate</span>
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ))}
      </div>
      {isDuplicateProjectDialogOpen && (
        <MDuplicateProjectDialog
          organizationId={selectedProject.organizationId}
          projectToDuplicate={selectedProject}
          onClose={() => {
            setIsDuplicateProjectDialogOpen(false)
          }}
          isOpen={isDuplicateProjectDialogOpen}
        />
      )}
    </>
  )
}

function IterationPreview({ lastIterationStatus, previewUrl }) {
  if (lastIterationStatus === ITERATION_STATUSES.RUNNING) {
    return (
      <div className="inline-flex h-8 w-8 items-center justify-center gap-2 rounded-md bg-stone-900 px-4 py-2 opacity-50">
        <div className="flex h-4 w-4 items-center justify-center p-px">
          <Loader className="text-stone-50" />
        </div>
      </div>
    )
  } else if (previewUrl) {
    return (
      <Link
        href={previewUrl}
        target={'_blank'}
        className="flex h-8 w-8 items-center justify-center gap-2 rounded-md bg-stone-900 px-4 py-2"
        onClick={() => {
          // analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_PREVIEW, {
          //   iterationId: selectedIteration?.id,
          //   projectId: selectedIteration?.projectId,
          //   teamId: selectedIteration?.teamId,
          //   organizationId: selectedIteration?.organizationId,
          //   status: selectedIteration?.status,
          //   vmStatus: selectedIteration?.vmStatus,
          // })
        }}
      >
        <div className="flex h-4 w-4 items-center justify-center p-px">
          <TvMinimal className="text-white" />
        </div>
      </Link>
    )
  } else {
    return (
      <div className="flex h-8 w-8 items-center justify-center gap-2 rounded-md bg-stone-900 px-4 py-2 opacity-50">
        <div className="flex h-4 w-4 items-center justify-center px-px py-[1.50px]">
          <TvMinimal className="text-white" />
        </div>
      </div>
    )
  }
}

function getIterationInfo(activeIterationStatus) {
  switch (activeIterationStatus) {
    case ITERATION_STATUSES.AWAITING:
      return [
        'Awaiting',
        'zinc-500',
        <PlaneTakeoff key="iteration-status-awaiting-icon" className="h-4 w-4 text-zinc-500" />,
      ]
    case ITERATION_STATUSES.RUNNING:
      return [
        'Running',
        'violet-500',
        <Logo key="iteration-status-running-icon" color={colors.violet[500]} className="h-4 w-4" />,
      ]
    case ITERATION_STATUSES.PAUSED:
      return [
        'Paused',
        'zinc-500',
        <PauseCircle key="iteration-status-paused-icon" className="h-4 w-4 text-zinc-500" />,
      ]
    case ITERATION_STATUSES.DONE:
      return [
        'Done',
        'green-500',
        <CheckCheck key="iteration-status-done-icon" className="h-4 w-4 text-green-500" />,
      ]
    case ITERATION_STATUSES.FAILED:
      return [
        'Failed',
        'red-500',
        <XOctagon key="iteration-status-failed-icon" className=" h-4 w-4 text-red-500" />,
      ]
    case ITERATION_STATUSES.RUN_REQUESTED:
      return [
        'Requested',
        'zinc-500',
        <TimerReset key="iteration-status-requested-icon" className="h-4 w-4 text-zinc-500" />,
      ]
    default:
      return [
        activeIterationStatus,
        'zinc-500',
        <Logo key="iteration-status-running-icon" color={colors.zinc[500]} className="h-4 w-4" />,
      ]
  }
}
