import { useMemo, useState } from 'react'
import { ChooseTechStackStep } from './ChooseTechStackStep/ChooseTechStackStep'
import { OnboardingProvider, useOnboardingContext } from './OnboardingProvider'
import { BuildPoCStep } from './BuildPoCStep'
import logoText from '@/assets/images/logo-text.svg'
import logo from '@/assets/images/logo.svg'

function POnboardingInner() {
  const { currentStepIndex } = useOnboardingContext()

  const STEPS = useMemo(() => {
    return [
      {
        title: 'Choose your preferred tech stacks',
        subtitle: `We'll keep you posted on updates to your picks`,
        content: <ChooseTechStackStep />,
      },
      {
        title: `Let's explore an example PoC build`,
        subtitle: `Lululemon and Shopify Hydrogen look  good together, so let's use them`,
        content: <BuildPoCStep />,
      },
    ]
  }, [])

  const currentStep = STEPS[currentStepIndex]

  return (
    <div className="h-screen w-screen py-12">
      <div className="mx-auto flex max-h-full max-w-[684px] flex-col gap-12">
        <div className="flex items-center justify-between">
          <img src={logoText} alt="proofs logo text" className="size-16" />
          <img src={logo} alt="proofs logo" className="size-6" />
        </div>
        <div className="space-y-2">
          <div className="text-3xl text-base-foreground">{currentStep.title}</div>
          <div className="text-sm text-base-muted-foreground">{currentStep.subtitle}</div>
        </div>
        <div className="flex-1 overflow-y-auto">{currentStep.content}</div>
      </div>
    </div>
  )
}

export function POnboarding() {
  return <POnboardingInner />
}
