const LululemonIcon = ({ className = '', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      fill="none"
      className={className}
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M30.159 15.888c0 7.872-6.39 14.26-14.261 14.26-7.877 0-14.266-6.388-14.266-14.26 0-7.878 6.389-14.266 14.266-14.266 7.872 0 14.26 6.388 14.26 14.266Z"
        />
        <mask
          id="b"
          width={32}
          height={32}
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path fill="#fff" d="M.495.497h31.018v31.018H.495V.497Z" />
        </mask>
        <g mask="url(#b)">
          <path
            fill="#D41935"
            d="M26.695 23.142c-1.248 1.867-2.84 2.86-4.614 2.86-.778 0-1.596-.19-2.435-.564-.89-.393-1.672-1.115-2.204-2.03-.527-.916-.737-1.903-.589-2.793.184-.778.594-1.836 1.07-3.064 1.232-3.192 2.925-7.565 1.462-9.703-.614-.9-1.724-1.34-3.38-1.346-1.663.005-2.768.445-3.387 1.346-1.458 2.138.23 6.511 1.468 9.708.47 1.223.885 2.281 1.064 3.07.153.879-.056 1.866-.588 2.782-.527.915-1.31 1.636-2.2 2.03-.839.374-1.662.563-2.435.563-1.775 0-3.37-.992-4.608-2.854l-.144-.256c.594.297 1.724.788 2.701.788.486 0 .947-.148 1.402-.45 2.506-1.668 1.652-3.673-.062-6.773-.726-1.314-1.473-2.67-1.805-4.071-.358-1.504-.686-3.699.624-5.356.608-.783 1.545-1.381 2.772-1.785 1.274-.425 2.916-.65 4.88-.68h.634c1.964.03 3.606.255 4.88.68 1.228.404 2.159 1.002 2.773 1.785 1.309 1.657.982 3.852.624 5.356-.333 1.401-1.08 2.757-1.806 4.071-1.714 3.1-2.573 5.105-.062 6.773.456.302.911.45 1.402.45.977 0 2.102-.491 2.7-.788l-.137.25ZM16.005.497C7.435.497.494 7.438.494 16.007c0 8.562 6.941 15.508 15.51 15.508 8.567 0 15.508-6.946 15.508-15.509 0-8.568-6.941-15.509-15.509-15.509Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LululemonIcon
