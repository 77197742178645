import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { Wand } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'

export default function DefaultAction({ action, isLast = false }) {
  return (
    <GenericAction
      action={action}
      detailsContent=""
      actionIcon={Wand}
      isLast={isLast}
      title={`${convertString(action.name)}`}
    >
      <AActionContentGenericLayout>
        <AActionContentDescription content={action.name} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

DefaultAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}

function convertString(input) {
  // Replace underscores with spaces
  let converted = input.replace(/_/g, ' ')

  // Capitalize the first letter
  converted = converted.charAt(0).toUpperCase() + converted.slice(1)

  return converted
}
