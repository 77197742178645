import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { CircleUser } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentUserSuggestion from '@/components/actions/components/AActionContentUserSuggestion.jsx'

export default function UserSuggestionAction({ action, isLast = false }) {
  return (
    <GenericAction
      action={action}
      detailsContent={`## User suggestion:\n\n${action?.args?.text}\n\n`}
      actionIcon={CircleUser}
      isLast={isLast}
      customOptions={{ iconStyle: 'text-amber-400', actionBodyStyle: 'bg-amber-50 text-red-500' }}
      title="User suggestion"
    >
      <AActionContentGenericLayout>
        <AActionContentUserSuggestion content={`! ` + action?.args?.text} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

UserSuggestionAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
